import { Typography } from "@mui/material";

import { CoAppNameCell, CoAppSuperAdminTextCell } from "../../styled/global.styled";

export default function CoAppDataGridNameCell(props) {
	const { item, isUser = false, isCoAppSuperAdmin = false } = props;

	if (isUser) {
		const showEmailOnly = !isCoAppSuperAdmin && (!item.firstname || !item.lastname);
		return (
			<CoAppNameCell>
				{showEmailOnly ? (
					<Typography variant="body2" color="text.primary">{item.email}</Typography>
				) : (
					item.firstname && item.lastname && !isCoAppSuperAdmin ? (
						<Typography variant="body2" color="text.primary">
							{item.firstname} {item.lastname}
						</Typography>
					) : (
						<CoAppSuperAdminTextCell before="true">
							{item.firstname} {item.lastname}
						</CoAppSuperAdminTextCell>
					)
				)}
				<Typography variant="body2" color={isCoAppSuperAdmin ? "text.disabled" : "text.secondary"}>
					{item.email}
				</Typography>
			</CoAppNameCell>
		);
	} else {
		return (
			<>
				<Typography title={item.name} variant="body2" color="text.primary" noWrap>{item.name}</Typography>
			</>
		);
	}
}