import { Alert, Slide, Snackbar } from "@mui/material";

const { CoAppTextButton } = require("../global/styled/global.styled");

const AlertTransition = (props) => {
	return <Slide {...props} direction="right" />;
};

export default function RuleUndoSnackbar(props) {
	const {
		level, message, open,
		setLevel, setMessage, setOpen,
		undoHandler
	} = props;

	const handleClick = () => {
		undoHandler();
		setOpen(false);
		setLevel("");
		setMessage("");
	};

	const handleClose = () => {
		setOpen(false);
		setLevel("");
		setMessage("");
	};

	const action = (
		<>
			<CoAppTextButton onClick={handleClick} sx={{ ":hover": { backgroundColor: "transparent" }, padding: "0px" }}>UNDO</CoAppTextButton>
		</>
	);

	return (
		<Snackbar
			autoHideDuration={6000}
			disableWindowBlurListener
			open={open}
			onClose={handleClose}
			TransitionComponent={AlertTransition}
		>
			<Alert open={open} onClick={handleClick} severity={level !== "" ? level : "info"} sx={{ alignItems: "center" }}>
				{message}
				{action}
			</Alert>
		</Snackbar>
	);
}