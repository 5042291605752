import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterAlt } from "@mui/icons-material";
import { Grid, Stack, Typography } from "@mui/material";
import { GridPagination, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-premium";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../hooks/useAuthHeader";
import { selectEditedVersionOfRule, selectRuleLocation, setReactions, setRuleLocation } from "../../../redux/ruleWizardSlice";
import CoAppDataGridImageCell from "../../global/components/datagrid/coapp-datagrid-image-cell";
import DataGridCustomNoRowsOverlay from "../../global/components/datagrid/datagrid-custom-norows-overlay";
import { CoAppActionButton, CoAppDataGridPaginationContainer, CoAppDataGridToolbar } from "../../global/styled/global.styled";
import RuleReactionSidebar from "../reactions/rule-reaction-sidebar";
import { RuleImage, RuleWizardDataGrid, WizardStepContainer } from "../styled/rule-wizard.styled";

export default function WizardStep2(props) {
	const dispatch = useDispatch();
	let authHeader = useAuthHeader();

	const ruleLocation = useSelector(selectRuleLocation);
	const editedVersionOfRule = useSelector(selectEditedVersionOfRule);

	const [ruleLocations, setRuleLocations] = useState([]);
	const [ruleLocationsLoaded, setRuleLocationLoaded] = useState(false);
	const [ruleLocationsPreviouslyLoaded, setRuleLocationsPreviouslyLoaded] = useState(false);

	const [location, setLocation] = useState({});
	const [locationLoaded, setLocationLoaded] = useState(false);

	const [reactionFields, setReactionFields] = useState([]);
	const [reactionFieldsLoaded, setReactionFieldsLoaded] = useState(false);

	const [reactionTypes, setReactionTypes] = useState([]);

	const initRuleLocations = () => {
		if (!ruleLocationsPreviouslyLoaded) {
			setRuleLocationLoaded(false);
			axios.get(apiRoutes.getRuleLocationsForWizard, {
				headers: authHeader
			})
				.then(res => {
					setRuleLocations(res.data);
					setRuleLocationLoaded(true);
				})
				.catch(err => console.log(err));

			setRuleLocationsPreviouslyLoaded(true);
		}
	};

	const initSelectedLocation = () => {
		setLocationLoaded(false);
		axios.get(apiRoutes.getRuleLocation + "/" + ruleLocation.id, {
			headers: authHeader
		})
			.then(res => {
				setLocation(res.data);
				setLocationLoaded(true);
				setReactionTypes(res.data.reactiontypes);
			})
			.catch(err => console.log(err));
	};

	const initReactionFields = () => {
		setReactionFieldsLoaded(false);
		axios.get(apiRoutes.ruleFields, {
			headers: authHeader,
			params: {
				locationId: ruleLocation.id
			}
		})
			.then(res => {
				setReactionFields(res.data);
				setReactionFieldsLoaded(true);
			})
			.catch(err => console.log(err));
	};

	const resetLocation = () => {
		dispatch(setRuleLocation(""));
		dispatch(setReactions([]));
		props.handleStepDataChange(true);
		setLocationLoaded(false);
		setReactionFieldsLoaded(false);
	};

	const selectLocation = (locationId) => {
		const location = ruleLocations.filter(location => location.id === locationId)[0];
		dispatch(setRuleLocation(location));
	};

	function CustomToolbar() {
		return (
			<div style={{ width: "100%" }}>
				<CoAppDataGridToolbar container>
					<Grid item xxl={0.1} xl={0.1} lg={0.2} sm={0.2} />
					<Grid item xxl={1} xl={1} lg={1.2} sm={1.5}>
						<GridToolbarFilterButton />
					</Grid>
					<Grid item xxl={4} xl={4} lg={3.5} sm={3}>
						<GridToolbarQuickFilter sx={{ width: { xxl: "220px", xl: "220px", lg: "200px", sm: "150px" } }} />
					</Grid>
					<CoAppDataGridPaginationContainer item>
						<GridPagination rowsPerPageOptions={[]} />
					</CoAppDataGridPaginationContainer>
				</CoAppDataGridToolbar>
			</div>
		);
	}

	const locationColumns = [
		{ field: "name", flex: 1, headerName: "Location name" },
		{ field: "description", flex: 5, headerName: "Description" },
		{
			field: "previewUrl", flex: 3, headerName: "Preview",
			renderCell: (params) => (
				<CoAppDataGridImageCell image={params.value} />
			)
		},
	];

	useEffect(() => {
		if (props.editedId && Object.keys(editedVersionOfRule).length > 0) {
			if (ruleLocation) {
				if (editedVersionOfRule.ruleLocation) {
					if (ruleLocation.id !== editedVersionOfRule.ruleLocation.id) {
						props.handleStepDataChange(true);
					}
				} else {
					props.handleStepDataChange(true);
				}
			}
		}
		if (ruleLocation) {
			initSelectedLocation();
			initReactionFields();
		}
		initRuleLocations();

	}, [ruleLocation, ruleLocationsLoaded]);

	if (locationLoaded && reactionFieldsLoaded) {
		return (
			<WizardStepContainer>
				<Grid alignItems="center" container justifyContent="space-evenly" spacing={2}>
					<Grid size={10}>
						<Stack spacing={2}>
							<>
								<Typography variant="h6">Add reactions to: {location.name}</Typography>
								<CoAppActionButton onClick={resetLocation}>Change Location</CoAppActionButton>
							</>
							<RuleImage src={ruleLocation.previewUrl} />
						</Stack>
					</Grid>

					<Grid size={4}>
						<RuleReactionSidebar reactionFields={reactionFields} handleStepDataChange={props.handleStepDataChange}
							editedId={props.editedId} reactionTypes={reactionTypes}
							handleSaveChanges={props.handleSaveChanges} />
					</Grid>
				</Grid>
			</WizardStepContainer>
		);
	} else if (ruleLocationsLoaded) {
		return (
			<WizardStepContainer>
				<Typography sx={{ marginTop: "15px" }} variant="h6">Where should your reaction happen?</Typography>
				<RuleWizardDataGrid
					columns={locationColumns}
					onLocationSelection={selectLocation}
					rows={ruleLocations}
					onRowSelectionModelChange={(locationId) => {
						selectLocation(locationId[0]);
					}}
					sx={{ cursor: "pointer" }}
					slots={{
						toolbar: CustomToolbar,
						noResultsOverlay: DataGridCustomNoRowsOverlay,
						noRowsOverlay: DataGridCustomNoRowsOverlay,
						openFilterButtonIcon: FilterAlt
					}}
					slotProps={{
						noResultsOverlay: { message: "No locations found." },
						noRowsOverlay: { message: "No locations found." }
					}}
					hideFooter
					disableColumnMenu
				/>
			</WizardStepContainer>
		);
	}
}