const ssoScimVideoLinks = {
	createSamlAppThumbnail: "https://cdn.loom.com/sessions/thumbnails/6cf4796c01d44554994dcb3e6ca5faf6-9267209749b3263e-full-play.gif",
	createSamlAppVideo: "https://www.loom.com/embed/6cf4796c01d44554994dcb3e6ca5faf6?sid=9dc93229-089c-4271-963f-0ce1277afc15",
	configureSamlAppThumbnail: "https://cdn.loom.com/sessions/thumbnails/ffe7ce7d912345ffbabe875b02da98df-bc8ce4f1703d862d-full-play.gif",
	configureSamlAppVideo: "https://www.loom.com/embed/ffe7ce7d912345ffbabe875b02da98df?sid=bc6a9bad-dd7a-4db0-b35a-844282c78e1a",
	assignGroupsThumbnail: "https://cdn.loom.com/sessions/thumbnails/d79a6513632d477994d8251104bac13e-fdf43f48dd723ed8-full-play.gif",
	assignGroupsVideo: "https://www.loom.com/embed/d79a6513632d477994d8251104bac13e?sid=cad13c14-4cf8-471e-ace0-49952557a911",
	configureClaimsAndMetadataUrlThumbnail: "https://cdn.loom.com/sessions/thumbnails/670bae427c7d47739b14b661a4a6eebc-80fc069da1be1d87-full-play.gif",
	configureClaimsAndMetatdataUrlVideo: "https://www.loom.com/embed/670bae427c7d47739b14b661a4a6eebc?sid=908200d9-d707-4ed1-af3f-25a45f6f183e",
	enterScimCredentialsThumbnail: "https://cdn.loom.com/sessions/thumbnails/01952a79141e4a3fa96e31ef71451897-54549de48cffb2f6-full-play.gif",
	enterScimCredentialsVideo: "https://www.loom.com/embed/01952a79141e4a3fa96e31ef71451897?sid=c38fcc94-5455-4a01-a708-4de8efacdf28",
	testScimConnectionThumbnail: "https://cdn.loom.com/sessions/thumbnails/e990442f5413443888e1851321a5d60a-2332592f5bde726b-full-play.gif",
	testScimConnectionVideo: "https://www.loom.com/embed/e990442f5413443888e1851321a5d60a?sid=5a89ec4d-41fa-48ab-a985-3410e3e7d31f",
	provisionGroupWithMembersThumbnail: "https://cdn.loom.com/sessions/thumbnails/5e800194cf844424b8a41f749ebb8e81-54c363ebdb83925f-full-play.gif",
	provisionGroupWithMembersVideo: "https://www.loom.com/embed/5e800194cf844424b8a41f749ebb8e81?sid=abba58b7-2d44-4b1e-b557-d9f9dd07f2f2",
	startScimProvisioningThumbnail: "https://cdn.loom.com/sessions/thumbnails/50d80bcad5fe4a58ad19ead7905c92c1-18b7bb65739e6ef6-full-play.gif",
	startScimProvisioningVideo: "https://www.loom.com/embed/50d80bcad5fe4a58ad19ead7905c92c1?sid=526caaf2-ac1c-434d-9a5e-59e0f3f1099c"
};

export default ssoScimVideoLinks;