import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Descope, useSession, useUser } from "@descope/react-sdk";
import {
	CircularProgress, Container, LinearProgress,
	Stack, Typography
} from "@mui/material";
import axios from "axios";
import jwtDecode from "jwt-decode";

import apiRoutes from "../../constants/api-routes";
import pages from "../../constants/pages";
import {
	setEmail, setFirstName, setGroups,
	setLastName, setOrganizationForceLogin, setOrganizationIdentityProvider,
	setOrganizationKillSwitch, setOrganizationSsoAndScim, setOrgId,
	setPermissions, setPhone, setRoles,
	setUserId
} from "../../redux/userSlice";
import { CoAppLoginContainer, CoAppLoginFlowContainer } from "../global/styled/global.styled";


export default function SignIn() {
	const [countdown, setCountdown] = useState(30);
	const { isAuthenticated, isSessionLoading } = useSession();
	const { isUserLoading } = useUser();
	const [loginCompleted, setLoginCompleted] = useState(false);
	const [progress, setProgress] = useState(0);
	const [userDoesNotExist, setUserDoesNotExist] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogin = (e) => {
		const decodedJwt = jwtDecode(e.detail.sessionJwt);
		let email = jwtDecode(e.detail.sessionJwt).email;
		let tenantId;
		if (decodedJwt.tenantId) {
			tenantId = decodedJwt.tenantId;
		}
		dispatch(setEmail(email));
		axios.get(apiRoutes.getUser(1, "", email, tenantId ? tenantId : null), { headers: { "Authorization": "Bearer " + e.detail.sessionJwt } })
			.then(res => {
				dispatch(setFirstName(res.data.firstname));
				dispatch(setLastName(res.data.lastname));
				dispatch(setGroups(res.data.groups));
				dispatch(setUserId(res.data.id));
				dispatch(setPhone(res.data.phone));
				dispatch(setOrganizationForceLogin(res.data.organization.forceLoginActive));
				dispatch(setOrganizationKillSwitch(res.data.organization.killSwitchActive));
				dispatch(setOrganizationSsoAndScim(res.data.organization.isSsoAndScimEnabled));
				if (res.data.organization.isSsoAndScimEnabled) {
					dispatch(setOrganizationIdentityProvider(res.data.organization.identityProviderName));
				}
				dispatch(setOrgId(res.data.organizationId));
				dispatch(setPermissions(res.data.permissions));
				dispatch(setRoles(res.data.roles));
				setLoginCompleted(true);
			})
			.catch(err => {
				axios.post(apiRoutes.syncByTenantId, { descopeTenantId: e.detail.user.userTenants[0].tenantId },
					{ headers: { "Authorization": "Bearer " + e.detail.sessionJwt } })
					.then(() => {
						setUserDoesNotExist(true);
					});
			});
	};

	useEffect(() => {
		let interval;
		if (userDoesNotExist) {
			interval = setInterval(() => {
				setCountdown(prevCountdown => {
					const newCountdown = prevCountdown - 1;
					setProgress(((30 - newCountdown) / 30) * 100);
					if (newCountdown <= 0) {
						clearInterval(interval);
						window.location.reload();
					}
					return newCountdown;
				});
			}, 1000);
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [userDoesNotExist, navigate]);

	useEffect(() => {
		if ((isAuthenticated && !isSessionLoading && !userDoesNotExist) || loginCompleted) {
			navigate(pages.home);
		}
	}, [isAuthenticated, isSessionLoading, userDoesNotExist, loginCompleted, navigate]);

	if (isSessionLoading || isUserLoading) {
		return (
			<Container sx={{ maxWidth: "150px!important" }}>
				<Stack>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	} else if (userDoesNotExist) {
		return (
			<Container sx={{ marginTop: "50px" }}>
				<Stack spacing={1} alignItems="center">
					<LinearProgress color="primary" variant="determinate" value={progress}
						sx={{
							width: "100%",
							backgroundColor: "#e0e0e0",
							"& .MuiLinearProgress-bar": {
								backgroundColor: "#2FBD70",
							},
						}} />
					<Typography>
						Please wait {Math.max(countdown, 0)} seconds and try logging in again.
					</Typography>
				</Stack>
			</Container>
		);
	} else {
		return (
			<CoAppLoginContainer>
				<CoAppLoginFlowContainer>
					<Descope
						flowId="sign-in-w-sync-and-ccs"
						onSuccess={handleLogin}
						onError={(e) => console.log("Could not log in!" + JSON.stringify(e))}
					/>
				</CoAppLoginFlowContainer>
			</CoAppLoginContainer>
		);
	}
}