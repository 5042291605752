import { getUserInitialState } from "./userSlice";

/**
 * Similar to a DB migration, anytime we update our persisted initial state (currently only user and navigation reducers), we need to 
 * add a migration to handle the update.
 * IMPORTANT NOTES:
 * - The version number of the persistConfig in store.js should be incremented by 1 each time the initial state of a persisted reducer 
 *   is changed and should map to the key in the migrations object written below so that redux-persist knows which migration to run.
 *   For example, if the version is 2 in persistConfig, the migrations object should have a key of 2.
 * - If a migration is not written, upon rebuild of the UI in a higher environment, the redux state will be out of sync with what is likely 
 * 	 already persisted in a users browser and break the UI, therefore, it is crucial to write migrations to ensure our users receive the
 *   best UX possible with the MP.
 */
export const migrations = {
	2: (state) => {
		if (!state.users.sortPreferences) {
			state.users.sortPreferences = getUserInitialState().sortPreferences;
		}
		return state;
	}
};