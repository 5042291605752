import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

import CoAppAzureIdpIcon from "../../../../global/components/icons/coapp-azure-idp-icon";
import CoAppTextField from "../../../../global/components/inputs/coapp-textfield";
import { ScimWizardIdpContainer, ScimWizardStepContainer } from "../styled/scim-wizard.styled";

export default function Step1(props) {
	const {
		domainError, setDomainError, companyDomain,
		setCompanyDomain, identityProviderName
	} = props;

	const handleCompanyDomainChange = (e) => {
		setDomainError("");
		setCompanyDomain(e.target.value);
	};

	return (
		<ScimWizardStepContainer spacing={4}>
			<Typography variant="h3_5">Enter your company SAML domain</Typography>
			<CoAppTextField
				FormHelperTextProps={{ style: { color: "#DE3730" } }}
				placeholder="Company domain"
				value={companyDomain}
				onChange={handleCompanyDomainChange}
				helperText={domainError !== "" ? domainError : "Required *"}
				error={domainError !== ""}
				sx={{ width: "23.5%" }}
			/>
			<Typography variant="h3_5">{"Select your identity provider (IdP)"}</Typography>
			<RadioGroup
				value={identityProviderName}
			>
				<ScimWizardIdpContainer>
					<CoAppAzureIdpIcon sx={{ position: "absolute", left: 60 }} />
					<FormControlLabel
						sx={{ position: "absolute", top: 110, left: 25 }}
						control={
							<Radio />
						}
						label="Microsoft Entra ID"
						labelPlacement="top"
						value="Azure"
					/>
				</ScimWizardIdpContainer>

			</RadioGroup>
		</ScimWizardStepContainer >
	);
}