const pages = {
	group: (id) => `/settings/groups/${id}`,
	editRule: (ruleId) => `/rules/${ruleId}`,
	groupManagement: "/settings/groups",
	groupPlugins: (groupId) => `/settings/groups/${groupId}/plugins`,
	groupRules: (groupId) => `/settings/groups/${groupId}/rules`,
	groupSSO: (groupId) => `/settings/groups/${groupId}/sso`,
	groupUsers: (groupId) => `/settings/groups/${groupId}/users`,
	home: "/rules/library",
	login: "/",
	newRule: "/rules/new",
	organizationManagement: "/settings/organization",
	pluginGroups: (id) => `/settings/plugins/${id}/groups`,
	pluginManagement: "/settings/plugins",
	profile: "/settings/users/",
	role: (id) => `/settings/roles/${id}`,
	rolePermissions: (roleId) => `/settings/roles/${roleId}/permissions`,
	roleUsers: (roleId) => `/settings/roles/${roleId}/users`,
	roleSSO: (roleId) => `/settings/roles/${roleId}/sso`,
	roleManagement: "/settings/roles",
	scimWizard: "/settings/organization/sso-scim",
	userManagement: "/settings/users",
	workstationManagement: "/settings/workstations"
};

export default pages;