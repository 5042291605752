import { Skeleton } from "@mui/material";

export default function CoAppDataGridSkeletonCell(props) {
	const { cellValue, height = 20, cellToRender } = props;

	return (
		<>
			{
				cellValue === ""
					?
					<Skeleton variant="rectangular" width="100%" height={height} sx={{ borderRadius: "10px" }} />
					:
					{ ...cellToRender }
			}
		</>
	);
}