import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { IconButton, Switch } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import navigationItemReferences from "../../../constants/navigation-item-references";
import { getDataGridFakeData } from "../../../helpers/getDataGridFakeData";
import useAuthHeader from "../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../../hooks/useDatagridSorting";
import useToastAlert from "../../../hooks/useToastAlert";
import { selectOrganizationId } from "../../../redux/userSlice";
import CoAppDatagridHeader from "../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppDataGridNameCell from "../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppDataGridSkeletonCell from "../../global/components/datagrid/coapp-datagrid-skeleton-cell";
import CoAppStandardDataGrid from "../../global/components/datagrid/coapp-standard-datagrid";

const pluginColumns = ["actions", "name", "groups", "active"];

export default function PluginManagement() {
	const dummyRows = getDataGridFakeData(pluginColumns);
	const { handleToastAlert } = useToastAlert();
	const authHeader = useAuthHeader();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("plugins");
	const { openResourceEditPage } = useCoAppNavigation();
	const organizationId = useSelector(selectOrganizationId);
	const [plugins, setPlugins] = useState(dummyRows);
	const [loading, setLoading] = useState(true);

	const columns = [
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			editable: false,
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={params.row} />}
				/>
			)
		},
		{
			field: "groups",
			headerName: "Groups",
			sortable: false,
			flex: 1,
			editable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridListCell items={params.value} resource="Groups" />}
				/>
			)
		},
		{
			field: "active",
			headerName: "Status",
			sortable: true,
			flex: 1,
			editable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<Switch
							checked={params.value}
							onChange={() => handlePluginStatusToggleChange(params.row.id, params.row.platformId, params.value)}
							disabled={false}
						/>
					}
				/>
			)
		},
		{
			field: "actions",
			type: "actions",
			sortable: false,
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<IconButton>
							<KeyboardArrowRight
								onClick={() => openResourceEditPage(params, navigationItemReferences.pluginNavItemIndex, "Plugins")}
							/>
						</IconButton>
					}
				/>
			)
		}
	];

	const handlePluginStatusToggleChange = (pluginId, platformId, state) => {
		axios.put(apiRoutes.togglePluginStatusForOrganization(organizationId, platformId, pluginId), {}, { headers: authHeader })
			.then(() => {
				initPluginManagement();
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, state ? messages.PLUGIN_DISABLED_ERROR_MSG : messages.PLUGIN_ENABLED_ERROR_MSG);
			});
	};

	const initPluginManagement = () => {
		axios.get(apiRoutes.getPlugins(organizationId), { headers: authHeader })
			.then((response) => {
				let platforms = response.data;
				let plugins = [];
				platforms.forEach(platform => {
					if (!platform.plugins) {
						return;
					}
					platform.plugins.forEach(plugin => {
						plugins.push({
							id: plugin.id,
							name: plugin.name,
							groups: plugin.groups.map(group => group),
							active: plugin.active,
							platformId: platform.id
						});
					});
				});

				setPlugins(plugins);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});

	};

	useEffect(() => {
		initPluginManagement();
		handleInitializeSortModel();
	}, []);

	return (
		<>
			<CoAppDatagridHeader
				title="Plugins"
				addIsPresent={false}
				editIsPresent={false}
				deleteIsPresent={false}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={plugins}
				pinnedColumns={["actions", "name", "active"]}
				allowSelection={false}
				targetResource="plugins"
				loading={loading}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
			/>
		</>
	);
}