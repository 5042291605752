
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";

import { CoAppDataGridMain, CoAppOrganizationSettingsMain, CoAppRuleWizardMain, CoAppUserProfileMain } from "./components/global/styled/global.styled";
import ResponsiveAppBar from "./components/header";
import CoAppNavDrawer from "./components/navigation/coapp-nav-drawer";
import pages from "./constants/pages";

const WithNavRoute = () => {
	const location = useLocation();
	const pathItems = location.pathname.split("/").slice(1);
	const isUserProfile = location.pathname.includes("users") && !isNaN(parseInt(pathItems[2]));
	const isOrganizationSettings = location.pathname === pages.organizationManagement;
	const isRuleEditOrCreate = location.pathname.includes("rules") && !location.pathname.includes("library") && !location.pathname.includes("trash");

	if (isRuleEditOrCreate) {
		return (
			<>
				<ResponsiveAppBar />
				<CoAppNavDrawer />
				<CoAppRuleWizardMain>
					<Outlet />
				</CoAppRuleWizardMain >
			</>
		);
	}
	if (isUserProfile) {
		return (
			<>
				<ResponsiveAppBar />
				<CoAppNavDrawer />
				<CoAppUserProfileMain>
					<Outlet />
				</CoAppUserProfileMain>
			</>
		);
	}
	if (isOrganizationSettings) {
		return (
			<>
				<ResponsiveAppBar />
				<CoAppNavDrawer />
				<CoAppOrganizationSettingsMain>
					<Outlet />
				</CoAppOrganizationSettingsMain>
			</>
		);
	}
	if (!isOrganizationSettings && !isRuleEditOrCreate && !isUserProfile) {
		return (
			<>
				<ResponsiveAppBar />
				<CoAppNavDrawer />
				<CoAppDataGridMain>
					<Outlet />
				</CoAppDataGridMain>
			</>
		);
	}
};

export default WithNavRoute;