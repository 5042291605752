import { Check, Clear } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

import { TagManagementTextField } from "./styled/tag-management.styled";

export default function TagEditOrCreateTextField(props) {
	const {
		value, valueError, handleValueChange,
		handleConfirmClick, handleCancelClick, editedTag = null
	} = props;

	return (
		<TagManagementTextField
			placeholder={editedTag ? editedTag.name : "Enter tag name"}
			value={value}
			error={valueError !== ""}
			helperText={valueError}
			onChange={handleValueChange}
			variant="standard"
			valueerror={valueError !== "" ? "true" : "false"}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton size="small" onClick={handleCancelClick}>
							<Clear sx={{ height: "20px", width: "20px" }} />
						</IconButton>
						<IconButton size="small" onClick={handleConfirmClick}>
							<Check sx={{ height: "20px", width: "20px", marginLeft: "2px" }} />
						</IconButton>
					</InputAdornment>
				)
			}}
			inputProps={{ maxLength: 20 }}
		/>
	);
}