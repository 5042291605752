/**
 * Generates fake data for the data grids to display the skeleton cell while the real data is fetched.
 * @param {*} columns columns of the data grid
 * @returns fake rows for the data grid
 */
export const getDataGridFakeData = (columns) => {
	let row = { isDummyRow: true };
	columns.forEach((column) => {
		row = {
			...row,
			[`${column}`]: ""
		};
	});

	const rows = Array.from({ length: 25 }, () => { return { ...row, id: Math.random(), value: "" }; });
	return rows;
};