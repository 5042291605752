import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Switch } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import navigationItemReferences from "../../../../constants/navigation-item-references";
import pages from "../../../../constants/pages";
import { getDataGridFakeData } from "../../../../helpers/getDataGridFakeData";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../../../hooks/useDatagridSorting";
import useToastAlert from "../../../../hooks/useToastAlert";
import CoAppDataGridDateTimeCell from "../../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppDataGridSkeletonCell from "../../../global/components/datagrid/coapp-datagrid-skeleton-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/coapp-add-items-to-list-modal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";
import ThreeDotMenu from "../../../global/components/three-dot-menu";

const groupRuleColumns = ["actions", "name", "active", "updatedAt", "createdAt"];

export default function GroupRules() {
	const dummyRows = getDataGridFakeData(groupRuleColumns);
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const { updateCoAppNavigation } = useCoAppNavigation();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("groupRules");
	const navigate = useNavigate();
	const { groupId } = useParams();
	const { pathname, state } = useLocation(); //state will have the nav object passed from the previous page, containing the current name

	const [addRulesIsToggled, setAddRulesIsToggled] = useState(false);
	const [availableRules, setAvailableRules] = useState([]);
	const [selectedRules, setSelectedRules] = useState([]);
	const [rulesToAdd, setRulesToAdd] = useState([]);
	const [groupRules, setGroupRules] = useState(dummyRows);
	const [editGroupIsToggled, setEditGroupIsToggled] = useState(false);
	const [deleteGroupIsToggled, setDeleteGroupIsToggled] = useState(false);
	const [removeRulesIsToggled, setRemoveRulesIsToggled] = useState(false);
	const [groupNameInput, setGroupNameInput] = useState("");
	const [groupNameError, setGroupNameError] = useState("");
	const [loading, setLoading] = useState(true);
	const [optionsLoading, setOptionsLoading] = useState(true);

	const columns = [
		{
			editable: false,
			field: "name",
			flex: 1,
			sortable: true,
			headerName: "Rule Name",
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={params.row} />}
				/>
			),
		},
		{
			editable: true,
			field: "active",
			headerName: "Status",
			width: 100,
			sortable: true,
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<Switch
							checked={params.row.active}
							disabled={params.row.isDraft}
							onChange={() => handleRuleStatusToggle(params.row.id, params.row.active)}
						/>
					}
				/>
			),
		},
		{
			editable: false,
			field: "updatedAt",
			flex: 1,
			headerName: "Modified",
			sortable: true,
			pinnable: false,
			valueGetter: (params) => params.value === "" ? "" : new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.value} />}
				/>
			),
		},
		{
			editable: false,
			field: "createdAt",
			flex: 1,
			headerName: "Created",
			sortable: true,
			pinnable: false,
			valueGetter: (params) => params.value === "" ? "" : new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.value} />}
				/>
			),
		},
		{
			field: "actions",
			disableExport: true,
			type: "actions",
			sortable: false,
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<div>
							<ThreeDotMenu
								options={[
									{
										name: "Remove From Group",
										optionClickHandler: () => {
											removeRuleFromGroup(params.row.id);
										},
									},
									{
										name: "View Rule",
										optionClickHandler: () => {
											navigate(pages.editRule(params.row.id));
										},
									},
								]}
							/>
						</div>
					}
				/>
			),
		},
	];

	const handleToggleGroupEditDialog = () => {
		setEditGroupIsToggled(!editGroupIsToggled);
		setGroupNameError("");
		setGroupNameInput("");
	};

	const handleGroupNameChange = (e) => {
		setGroupNameInput(e.target.value);
		setGroupNameError("");
	};

	const handleGroupNameChangeSubmit = () => {
		if (!groupNameInput) return;

		if (groupNameInput === state.name) {
			handleToggleGroupEditDialog();
			return;
		}

		axios.put(apiRoutes.updateGroup(groupId), { name: groupNameInput }, { headers: authHeader })
			.then(() => {
				setEditGroupIsToggled(false);
				updateCoAppNavigation(false, navigationItemReferences.groupNavItemIndex, groupNameInput, pathname);
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				handleToggleGroupEditDialog();
				handleToastAlert(messageLevels.ERROR, messages.GROUP_NAME_UPDATE_ERROR_MSG);
			});
	};

	const handleToggleDeleteGroupDialog = () => {
		setDeleteGroupIsToggled(!deleteGroupIsToggled);
	};

	const confirmGroupDeletion = () => {
		axios.delete(apiRoutes.deleteGroup + "/" + groupId, {
			headers: authHeader
		}).then(() => {
			handleToastAlert(messageLevels.INFO, messages.GROUP_DELETED_SUCCESS_MSG(false));
			updateCoAppNavigation(true, navigationItemReferences.groupNavItemIndex);
			navigate(pages.groupManagement, { replace: true });
			handleToggleDeleteGroupDialog();
		}).catch((err) => {
			console.log(err);
			handleToastAlert(messageLevels.ERROR, messages.GROUP_DELETION_ERROR_MSG);
			handleToggleDeleteGroupDialog();
		});
	};

	const addRulesToGroup = () => {
		let requestJson = {
			ruleIds: rulesToAdd.map((rule) => rule.id)
		};
		axios.post(apiRoutes.addRulesToGroup(groupId), requestJson, { headers: authHeader })
			.then(() => {
				init();
				toggleAddNewDialog();
				handleToastAlert(messageLevels.SUCCESS, messages.RULE_ADDED_SUCCESS_MSG);
			})
			.catch((error) => {
				console.log(error);
				toggleAddNewDialog();
				handleToastAlert(messageLevels.ERROR, messages.RULE_ADDED_ERROR_MSG);
			});
	};

	const addRuleToRulesToAdd = (value) => {
		//value is an array of rule names, we need to convert them to rule objects to display name properly
		value = value.map((rule) => {
			let tempArr = [...groupRules, ...availableRules];
			let foundRule = tempArr.find((row) => row.name === rule);
			return foundRule;
		});
		setRulesToAdd(value);
	};

	const init = () => {
		let ruleQueryObject = {
			groupId: groupId,
		};
		axios.get(apiRoutes.getRules, {
			headers: authHeader,
			params: ruleQueryObject,
		})
			.then((response) => {
				setGroupRules(response.data.rules);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const initAvailableRules = () => {
		axios.get(apiRoutes.getRules, { headers: authHeader })
			.then((response) => {
				let availableRules = response.data.rules.filter((rule) => {
					let ruleIndex = groupRules.findIndex((groupRule) => groupRule.id === rule.id);
					return ruleIndex === -1;
				});
				setAvailableRules(availableRules);
				setOptionsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const removeRuleFromGroup = (ruleId) => {
		axios.delete(apiRoutes.removeRuleFromGroup(groupId, ruleId), { headers: authHeader })
			.then(() => {
				init();
				handleToastAlert(messageLevels.INFO, messages.RULE_REMOVED_SUCCESS_MSG(false));
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, messages.RULE_REMOVED_ERROR_MSG);
			});
	};

	const bulkRemoveRulesFromGroup = () => {
		const deleteData = {
			ruleIds: selectedRules
		};

		axios.delete(apiRoutes.bulkRemoveRulesFromGroup(groupId), { headers: authHeader, data: deleteData })
			.then(() => {
				init();
				setRemoveRulesIsToggled(false);
				handleToastAlert(messageLevels.INFO, messages.RULE_REMOVED_SUCCESS_MSG(selectedRules.length > 1));
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, messages.RULE_REMOVED_ERROR_MSG);
			});
	};

	const handleRuleStatusToggle = (ruleId, currentStatus) => {
		axios.put(apiRoutes.toggleRuleStatus + "/" + ruleId, {}, {
			headers: authHeader
		})
			.then(() => {
				init();
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, currentStatus ? messages.RULE_DEACTIVATION_SUCCESS_MSG : messages.RULE_ACTIVATION_SUCCESS_MSG);
			});
	};

	const toggleAddNewDialog = () => {
		if (!addRulesIsToggled) {
			initAvailableRules();
			setAddRulesIsToggled(true);
		} else {
			setAddRulesIsToggled(false);
			setAvailableRules([]);
			setRulesToAdd([]);
			setOptionsLoading(true);
		}
	};

	useEffect(() => {
		init();
		handleInitializeSortModel();
	}, [state.name]);

	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} Rule Management`}
				subResourceType="Rule"
				resourceType="Group"
				addOnClickHandler={toggleAddNewDialog}
				addIsPresent={true}
				editIsPresent={true}
				editOnClickHandler={handleToggleGroupEditDialog}
				deleteIsPresent={true}
				deleteOnClickHandler={handleToggleDeleteGroupDialog}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={groupRules}
				pinnedColumns={["actions", "__check__", "name", "status"]}
				allowSelection={true}
				targetResource="rules"
				parentResource="group"
				parentResourceName={state.name}
				bulkActionText="Remove"
				selectionModel={selectedRules}
				setSelectionModel={setSelectedRules}
				handleModalToggle={() => setRemoveRulesIsToggled(prev => !prev)}
				loading={loading}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
			/>
			<CoAppAddItemsToListModal
				addButtonTitle="Save"
				inputLabel="Rule(s)"
				addItemsIsToggled={addRulesIsToggled}
				addItemsToCollection={addRulesToGroup}
				addItemsToNewArray={addRuleToRulesToAdd}
				dialogTitle={`Add rule(s) to ${state.name} group`}
				items={availableRules}
				itemsToAdd={rulesToAdd}
				toggleDialog={toggleAddNewDialog}
				loading={optionsLoading}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteGroupIsToggled}
				dialogTitle={`Delete ${state.name} group?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "group")}
				confirmClickHandler={confirmGroupDeletion}
				cancelClickHandler={handleToggleDeleteGroupDialog}
				actionText="Delete"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={removeRulesIsToggled}
				dialogTitle={`Remove ${selectedRules.length > 1 ? "rules" : "rule"} from ${state.name} group?`}
				dialogMessage={`Are you sure that you want to remove the selected ${selectedRules.length > 1 ? "rules" : "rule"} from the ${state.name} group?`}
				confirmClickHandler={bulkRemoveRulesFromGroup}
				cancelClickHandler={() => setRemoveRulesIsToggled(false)}
				actionText="Remove"
			/>
			<CoAppEditNameModal
				dialogOpen={editGroupIsToggled}
				dialogTitle="Edit group name"
				changeHandler={handleGroupNameChange}
				placeholderText={state.name}
				confirmClickHandler={handleGroupNameChangeSubmit}
				cancelClickHandler={handleToggleGroupEditDialog}
				actionText="Save"
				editNameError={groupNameError}
				setEditNameError={setGroupNameError}
			/>
		</>
	);
}