import { useSelector } from "react-redux";
import Warning from "@mui/icons-material/Warning";
import { Card } from "@mui/material";

import messages from "../../../constants/messages";
import { selectPermissions } from "../../../redux/userSlice";
import ResponsiveAppBar from "../../header";
import { CoAppActionButton, CoAppBasicContainer, CoAppH2 } from "../styled/global.styled";

export default function NoPageFound() {
	const permissions = useSelector(selectPermissions);
	return (
		<>
			<ResponsiveAppBar />
			<CoAppBasicContainer>
				<Card
					sx={{
						alignItems: "center",
						border: "none",
						borderRadius: "0px",
						boxShadow: "none",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						height: "497px",
						width: "1364px",
						marginTop: "50px"
					}}
				>
					<Warning sx={{ fontSize: "50px", color: "#D32F2F" }} />
					<CoAppH2>{messages.PAGE_DOES_NOT_EXIST_MSG}</CoAppH2>
					<p style={{ margin: "0px" }}>{messages.CONTACT_ADMIN_MSG}</p>
					{
						permissions && permissions.includes("ACCESS_MP") ?
							< CoAppActionButton onClick={() => window.history.back()} sx={{ marginTop: "20px" }}>
							Go Back
							</CoAppActionButton>
							:
							null
					}
				</Card>
			</CoAppBasicContainer>
		</>
	);
}