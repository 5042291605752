import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import axios from "axios";

import apiRoutes from "../../../../../constants/api-routes";
import messageLevels from "../../../../../constants/message-levels";
import messages from "../../../../../constants/messages";
import useAuthHeader from "../../../../../hooks/useAuthHeader";
import useToastAlert from "../../../../../hooks/useToastAlert";
import { selectOrganizationId } from "../../../../../redux/userSlice";
import CoAppCreateGroupOrGroupModal from "../../../../global/components/modals/coapp-create-group-or-role-modal";
import CoAppDestructiveConfirmationModal from "../../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../../global/components/modals/coapp-edit-name-modal";
import ThreeDotMenu from "../../../../global/components/three-dot-menu";
import { CoAppInvertedActionButton } from "../../../../global/styled/global.styled";
import IdpGroupAutocompleteCell from "../idp-group-autocomplete-cell";
import ScimWizardDataGrid from "../scim-wizard-datagrid";
import { ScimWizardDataGridStepContainer, ScimWizardRoleNameCell } from "../styled/scim-wizard.styled";

export default function Step6(props) {
	const { identityProviderName, mappedGroups, setMappedGroups } = props;
	const authHeader = useAuthHeader();
	const organizationId = useSelector(selectOrganizationId);
	const { handleToastAlert } = useToastAlert();
	const apiRef = useGridApiRef();
	const [groups, setGroups] = useState([]);
	const [idpGroups, setIdpGroups] = useState([]);
	const [groupName, setGroupName] = useState("");
	const [groupNameError, setGroupNameError] = useState();
	const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false);
	const [editGroupDialogOpen, setEditGroupDialogOpen] = useState(false);
	const [groupBeingEdited, setGroupBeingEdited] = useState({});
	const [deleteGroupDialogOpen, setDeleteGroupDialogOpen] = useState(false);
	const [groupBeingDeleted, setGroupBeingDeleted] = useState({});

	const columns = [
		{
			field: "name",
			headerName: "CoApp groups",
			sortable: true,
			flex: 1,
			renderCell: (params) => <ScimWizardRoleNameCell variant="body2" noWrap={true}>{params.value}</ScimWizardRoleNameCell>
		},
		{
			field: "idpGroups",
			headerName: `${identityProviderName} groups`,
			sortable: false,
			flex: 1,
			renderCell: (params) => (
				<IdpGroupAutocompleteCell
					options={idpGroups}
					mapped={mappedGroups}
					setMapped={setMappedGroups}
					resourceName={params.row.name}
					apiRef={apiRef}
					initializeGroupOptions={initializeExternalGroups}
					setExternalGroups={setIdpGroups}
				/>
			)
		},
		{
			field: "actions",
			type: "actions",
			sortable: false,
			flex: .1,
			renderCell: (params) => (
				<div>
					<ThreeDotMenu
						options={[
							{
								name: "Edit",
								optionClickHandler: () => {
									toggleEditGroupDialog(params.row);
								},
							},
							{
								name: "Delete",
								optionClickHandler: () => {
									toggleDeleteGroupDialog(params.row);
								},
							},
						]}
					/>
				</div>
			)
		}
	];

	const toggleCreateGroupDialog = () => {
		setCreateGroupDialogOpen(!createGroupDialogOpen);
		setGroupNameError("");
		setGroupName("");
	};

	const toggleEditGroupDialog = (group = {}) => {
		setEditGroupDialogOpen(!editGroupDialogOpen);
		setGroupNameError("");
		setGroupName("");
		setGroupBeingEdited(group);
	};

	const toggleDeleteGroupDialog = (group = {}) => {
		setDeleteGroupDialogOpen(!deleteGroupDialogOpen);
		setGroupBeingDeleted(group);
	};

	const handleGroupNameChange = (e) => {
		setGroupName(e.target.value);
		setGroupNameError("");
	};

	const createNewGroup = () => {
		if (!groupName) {
			setGroupNameError("Required *");
			return;
		}

		let groupJSON = {
			"groupName": groupName,
		};

		axios.post(apiRoutes.createGroup, groupJSON, { headers: authHeader })
			.then(() => {
				toggleCreateGroupDialog();
				initializeOrganizationGroups();
				handleToastAlert(messageLevels.SUCCESS, messages.GROUP_CREATED_SUCCESS_MSG);
			})
			.catch((err) => {
				if (err.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				toggleCreateGroupDialog();
				handleToastAlert(messageLevels.ERROR, messages.GROUP_CREATION_ERROR_MSG);
			});
	};

	const handleGroupNameChangeSubmit = () => {
		if (!groupName) return;

		if (groupName === groupBeingEdited.name) {
			toggleEditGroupDialog();
			return;
		}

		axios.put(apiRoutes.updateGroup(groupBeingEdited.id), { name: groupName }, { headers: authHeader })
			.then(() => {
				initializeOrganizationGroups();
				toggleEditGroupDialog();
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				toggleEditGroupDialog();
				handleToastAlert(messageLevels.ERROR, messages.GROUP_NAME_UPDATE_ERROR_MSG);
			});
	};

	const deleteGroup = () => {
		axios.delete(apiRoutes.deleteGroup + "/" + groupBeingDeleted.id, { headers: authHeader, })
			.then(() => {
				handleToastAlert(messageLevels.INFO, messages.GROUP_DELETED_SUCCESS_MSG(false));
				initializeOrganizationGroups();
				toggleDeleteGroupDialog();
			})
			.catch((err) => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.GROUP_DELETION_ERROR_MSG);
				toggleDeleteGroupDialog();
			});
	};

	const initializeOrganizationGroups = () => {
		axios.get(apiRoutes.getGroups, { headers: authHeader })
			.then((res) => {
				setGroups(res.data);
				let tempMappedGroups = { ...mappedGroups };
				for (let group of res.data) {
					if (Object.keys(groupBeingEdited).length > 0 && group.id === groupBeingEdited.id) {
						tempMappedGroups[`${group.name}`] = tempMappedGroups[`${groupBeingEdited.name}`];
					} else {
						tempMappedGroups[`${group.name}`] = !tempMappedGroups[`${group.name}`] ? [] : tempMappedGroups[`${group.name}`];
					}
				}
				if (Object.keys(groupBeingDeleted).length > 0) {
					delete tempMappedGroups[`${groupBeingDeleted.name}`];
				}
				if (Object.keys(groupBeingEdited).length > 0) {
					delete tempMappedGroups[`${groupBeingEdited.name}`];
				}
				setMappedGroups(tempMappedGroups);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const initializeExternalGroups = async () => {
		axios.get(apiRoutes.getExternalGroups(organizationId), {
			headers: authHeader
		})
			.then((res) => {
				setIdpGroups(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		initializeOrganizationGroups();
	}, []);

	return (
		<ScimWizardDataGridStepContainer>
			<Stack direction="row" spacing={10} sx={{ paddingLeft: "55px" }}>
				<Typography variant="h3_5">Map Groups</Typography>
				<CoAppInvertedActionButton onClick={toggleCreateGroupDialog}>
					Add Group
				</CoAppInvertedActionButton>
			</Stack>
			<ScimWizardDataGrid
				apiRef={apiRef}
				rows={groups}
				columns={columns}
			/>
			<CoAppCreateGroupOrGroupModal
				cancelClickHandler={toggleCreateGroupDialog}
				changeHandler={handleGroupNameChange}
				dialogOpen={createGroupDialogOpen}
				nameError={groupNameError}
				confirmClickHandler={createNewGroup}
				isGroup={true}
			/>
			<CoAppEditNameModal
				dialogOpen={editGroupDialogOpen}
				dialogTitle="Edit group name"
				changeHandler={handleGroupNameChange}
				placeholderText={groupBeingEdited.name}
				confirmClickHandler={handleGroupNameChangeSubmit}
				cancelClickHandler={toggleEditGroupDialog}
				actionText="Save"
				editNameError={groupNameError}
				setEditNameError={setGroupNameError}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteGroupDialogOpen}
				dialogTitle={`Delete ${groupBeingDeleted.name} group?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(groupBeingDeleted.name, "group")}
				confirmClickHandler={deleteGroup}
				cancelClickHandler={toggleDeleteGroupDialog}
				actionText="Delete"
			/>
		</ScimWizardDataGridStepContainer>
	);
}