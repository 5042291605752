import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	email: null,
	firstName: null,
	groups: [],
	lastName: null,
	organizationId: null,
	organizationForceLogin: null,
	organizationIdentityProvider: null,
	organizationKillSwitch: null,
	organizationSsoAndScim: null,
	permissions: [],
	phone: null,
	roles: [],
	userId: null,
	sortPreferences: {
		users: { lastSortedAs: "", column: "" },
		groups: { lastSortedAs: "", column: "" },
		groupRules: { lastSortedAs: "", column: "" },
		groupUsers: { lastSortedAs: "", column: "" },
		groupPlugins: { lastSortedAs: "", column: "" },
		groupIdpGroups: { lastSortedAs: "", column: "" },
		roles: { lastSortedAs: "", column: "" },
		roleUsers: { lastSortedAs: "", column: "" },
		roleIdpGroups: { lastSortedAs: "", column: "" },
		workstations: { lastSortedAs: "", column: "" },
		plugins: { lastSortedAs: "", column: "" },
		pluginGroups: { lastSortedAs: "", column: "" },
		ruleLibrary: { lastSortedAs: "", column: "" },
		ruleTrash: { lastSortedAs: "", column: "" }
	}
};

export const getUserInitialState = () => initialState;

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setFirstName: (state, action) => {
			state.firstName = action.payload;
		},
		setGroups: (state, action) => {
			state.groups = action.payload;
		},
		setLastName: (state, action) => {
			state.lastName = action.payload;
		},
		setOrgId: (state, action) => {
			state.organizationId = action.payload;
		},
		setOrganizationForceLogin: (state, action) => {
			state.organizationForceLogin = action.payload;
		},
		setOrganizationIdentityProvider: (state, action) => {
			state.organizationIdentityProvider = action.payload;
		},
		setOrganizationKillSwitch: (state, action) => {
			state.organizationKillSwitch = action.payload;
		},
		setOrganizationSsoAndScim: (state, action) => {
			state.organizationSsoAndScim = action.payload;
		},
		setPermissions: (state, action) => {
			state.permissions = action.payload;
		},
		setPhone: (state, action) => {
			state.phone = action.payload;
		},
		setRoles: (state, action) => {
			state.roles = action.payload;
		},
		setUserId: (state, action) => {
			state.userId = action.payload;
		},
		setSortPreferences: (state, action) => {
			state.sortPreferences = action.payload;
		}
	}
});

export const selectEmail = (state) => state.root.user.email;
export const selectFirstName = (state) => state.root.user.firstName;
export const selectGroups = (state) => state.root.user.groups;
export const selectLastName = (state) => state.root.user.lastName;
export const selectOrganizationId = (state) => state.root.user.organizationId;
export const selectOrganizationForceLogin = (state) => state.root.user.organizationForceLogin;
export const selectOrganizationIdentityProvider = (state) => state.root.user.organizationIdentityProvider;
export const selectOrganizationKillSwitch = (state) => state.root.user.organizationKillSwitch;
export const selectOrganizationSsoAndScim = (state) => state.root.user.organizationSsoAndScim;
export const selectPermissions = (state) => state.root.user.permissions;
export const selectPhone = (state) => state.root.user.phone;
export const selectRoles = (state) => state.root.user.roles;
export const selectSortPreferences = (state) => state.root.user.sortPreferences;
export const selectUser = (state) => state.root.user;
export const selectUserId = (state) => state.root.user.userId;


export const {
	setEmail,
	setFirstName,
	setGroups,
	setLastName,
	setPermissions,
	setPhone,
	setRoles,
	setUserId,
	setOrgId,
	setOrganizationForceLogin,
	setOrganizationIdentityProvider,
	setOrganizationKillSwitch,
	setOrganizationSsoAndScim,
	setSortPreferences
} = userSlice.actions;

export default userSlice.reducer;