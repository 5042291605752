import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";

import { CoAppActionButton, CoAppCancelTextButton, CoAppCloseIcon } from "../../../global/styled/global.styled";

export default function SsoScimWizardPreConfigurationModal(props) {
	const {
		dialogOpen, confirmationTitle, confirmationMessage,
		confirmationListItems, confirmClickHandler, cancelClickHandler
	} = props;
	const theme = useTheme();

	return (
		<Dialog open={dialogOpen} onClose={cancelClickHandler} PaperProps={{ sx: { borderRadius: "10px" } }}>
			<DialogTitle variant="h6" sx={{ marginLeft: "5px", marginTop: "15px" }}>{confirmationTitle}</DialogTitle>
			<CoAppCloseIcon onClick={cancelClickHandler} />
			<DialogContent>
				<Typography color="text.primary" variant="body1" sx={{ fontWeight: 500, marginLeft: "10px" }}>{confirmationMessage}</Typography>
				<ul>
					{confirmationListItems.map((item, index) => (
						<li key={index} style={{ marginBottom: "10px", color: index === 2 ? theme.palette.error.main : theme.palette.text.primary }}>{item}</li>
					))}
				</ul>
			</DialogContent>
			<DialogActions sx={{ marginBottom: "20px", marginRight: "20px" }}>
				<CoAppCancelTextButton onClick={cancelClickHandler}>Cancel</CoAppCancelTextButton>
				<CoAppActionButton onClick={confirmClickHandler}>Proceed</CoAppActionButton>
			</DialogActions>
		</Dialog>
	);
}