import { Typography } from "@mui/material";

import { CoAppDataGridAllSelectedContainer, CoAppTextButton } from "../../styled/global.styled";

export default function CoAppDataGridAllSelectedRow(props) {
	const {
		count, selectedResource, onClickHandler,
		parentResource = "", parentResourceName = "", shouldShow = false
	} = props;

	return (
		<>
			{
				shouldShow
					?
					(<CoAppDataGridAllSelectedContainer>
						< Typography variant="buttonSmall" color="text.coappPrimary" >
							All {count} {selectedResource} {parentResource && parentResourceName ? `in the ${parentResourceName} ${parentResource}` : ""} are selected.
						</Typography >
						<CoAppTextButton isrow="true" onClick={onClickHandler}>
							Clear selection
						</CoAppTextButton>
					</CoAppDataGridAllSelectedContainer >)
					:
					null
			}
		</>
	);
}