import React, { useEffect, useState } from "react";
import { AppBar, Box, Button, CircularProgress, Container, Stack, Toolbar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../hooks/useAuthHeader";
import { CoAppMainContainer } from "../../global/styled/global.styled";

import FieldCreationForm from "./field-creation-form";

export default function FieldManagement() {
	const authHeader = useAuthHeader();

	const [fields, setFields] = useState([]);
	const [fieldsLoaded, setFieldsLoaded] = useState(false);

	const columns = [
		{
			field: "id",
			headerName: "id",
			width: 100,
			editable: true,
		},
		{
			field: "active",
			headerName: "Active",
			width: 100,
			editable: true,
			type: "boolean"
		},
		{
			field: "fieldType",
			headerName: "Field Type",
			width: 220,
			editable: true
		},
		{
			field: "columnId",
			headerName: "Column ID",
			width: 220,
			editable: true,
			align: "left",
			headerAlign: "left",
		},
		{
			field: "friendlyName",
			headerName: "Friendly Name",
			width: 220,
			editable: true,
		},
		{
			field: "tableId",
			headerName: "Table ID",
			width: 220,
			editable: true,
		},
		{
			field: "tableName",
			headerName: "Table Name",
			width: 220,
			editable: true,
		},
		{
			field: "htmlId",
			headerName: "HTML ID",
			width: 300,
			editable: true,
		},
		{
			field: "name",
			headerName: "Name",
			width: 220,
			editable: true,
		},
		{
			field: "otherInstanceEnabled",
			headerName: "Other Instance Enabled",
			width: 100,
			editable: true,
			type: "boolean",
		},
		{
			field: "platformId",
			headerName: "Platform ID",
			width: 100,
			editable: true,
		},
		{
			field: "ruleLocationId",
			headerName: "Rule location ID",
			width: 100,
			editable: true,
		}
	];

	const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

	const handleCreateDialogClickOpen = () => {
		setCreateDialogOpen(true);
	};

	const initFields = () => {
		if (!fieldsLoaded) {
			axios.get(apiRoutes.ruleFields, {
				headers: authHeader,
			}).then((res) => {
				setFields(res.data);
				setFieldsLoaded(true);
			}).catch((err) => {
				console.log(err);
			});
		}
	};

	const processRowUpdate = (newRow) => {
		const updatedField = { ...newRow, isNew: false };
		axios.put(apiRoutes.ruleField + "/" + updatedField.id,
			{ newRow },
			{ headers: authHeader, }
		).then((response) => {
			console.log(response);
		}).catch((error) => {
			console.log(error);
		});
		return updatedField;
	};

	const handleProcessRowUpdateError = () => {
		console.log("row update error");
	};

	useEffect(() => {
		initFields();
	}, [fields]);

	if (fieldsLoaded) {
		return (
			<>
				<AppBar position="static" sx={{ backgroundColor: "#FFFFFF" }}>
					<Container maxWidth="xl">
						<Toolbar disableGutters>
							<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
							<Box sx={{ flexGrow: .025 }}>
								<Button color="inherit" sx={{ color: "#416856" }} onClick={handleCreateDialogClickOpen}>
									Create New Field
								</Button>
							</Box>
						</Toolbar>
					</Container>
				</AppBar>

				<FieldCreationForm isOpen={{ createDialogOpen }} />

				<CoAppMainContainer sx={{ p: 2 }}>
					<DataGrid
						rows={fields}
						columns={columns}
						processRowUpdate={processRowUpdate}
						onProcessRowUpdateError={handleProcessRowUpdateError}
						experimentalFeatures={{ newEditingApi: true }}
						disableRowSelectionOnClick />
				</CoAppMainContainer>
			</>

		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}