import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "@mui/icons-material";
import { Box, FormControl, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../hooks/useAuthHeader";
import {
	selectDescription, selectGroups,
	selectItemsInFormula, selectName, selectReactions, selectRuleLocation,
	selectTags, setGroups
} from "../../../redux/ruleWizardSlice";
import {
	RuleFormulaSummary, RuleFormulaSummaryItem, RuleSummary,
	WizardHeaderTag,
	WizardStepContainer
} from "../styled/rule-wizard.styled";

/**
 * Step 5 in Rule Creation Wizard
 * User confirms Rule prior to persistence
*/
export default function WizardStep5(props) {
	const dispatch = useDispatch();
	let authHeader = useAuthHeader();

	const name = useSelector(selectName);
	const description = useSelector(selectDescription);
	const groups = useSelector(selectGroups);
	const tags = useSelector(selectTags);
	const ruleLocation = useSelector(selectRuleLocation);
	const reactions = useSelector(selectReactions);
	const itemsInFormula = useSelector(selectItemsInFormula);

	const [groupOptions, setGroupOptions] = useState([]);
	const [ruleGroups, setRuleGroups] = useState([]);

	const handleGroupsChange = (event) => {
		setRuleGroups(event.target.value);
		dispatch(setGroups(event.target.value));
	};

	const initGroups = () => {
		axios.get(apiRoutes.getGroups, {
			headers: authHeader,
			params: { name: "" }
		}).then(res => {
			setGroupOptions(res.data);
		}).catch(err => {
			console.log(err);
		});
	};

	useEffect(() => {
		initGroups();
		if (props.editedId) {
			setRuleGroups(groups);
		}
	}, []);

	return (
		<WizardStepContainer>
			<Stack spacing={2}>
				<Typography variant="h6">Review the rule</Typography>
				<Grid item xs={12}>
					<RuleSummary>
						<Stack direction="row" spacing={2}>
							<Typography sx={{fontWeight: 600}}>Rule name: </Typography>
							<Typography>{name}</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<Typography sx={{fontWeight: 600}}>Rule description: </Typography>
							<Typography>{description}</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							{
								!ruleLocation || !ruleLocation.name || ruleLocation.name === "" ?
									<>
										<Typography sx={{fontWeight: 600}}>Rule reaction location: </Typography>
										<Typography>No location selected</Typography>
									</>
									:
									<>
										<Typography sx={{fontWeight: 600}}>Rule reaction location: </Typography>
										<Typography>{ruleLocation.name}</Typography>
									</>
							}
						</Stack>
					
						<Typography sx={{fontWeight: 600}}>Formula: </Typography>
						<RuleFormulaSummary>
							{
								itemsInFormula.map((item, index) =>
									<RuleFormulaSummaryItem key={index}>
										{item.triggerName}
									</RuleFormulaSummaryItem>
								)}
						</RuleFormulaSummary>
					</RuleSummary>

					<RuleSummary>
						<Typography>What groups do you want to assign the rule to?</Typography>
						<FormControl fullWidth>
							<Select id="user-select-group" value={ruleGroups} label="Groups"
								onChange={handleGroupsChange} multiple renderValue={(selected) => selected.join(", ")}>
								{
									groupOptions.map(group =>
										<MenuItem key={group.id} value={group.name}>
											<Checkbox checked={ruleGroups.indexOf(group.name) > -1} sx={{
												[`&.${checkboxClasses.checked}`]: {
													color: "#A9A9A9",
												}
											}} />
											{group.name}
										</MenuItem>
									)
								}
							</Select>
						</FormControl>
					</RuleSummary>

					<RuleSummary>
						<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
							<Typography sx={{ fontWeight: 600, marginRight: "10px" }}>Tags: </Typography>
							{
								tags.map((tag, index) =>
									<WizardHeaderTag key={index} sx={{ marginRight: 3 }}>
										<Label fontSize="small" sx={{ paddingRight: 1 }} />
										{tag.toUpperCase()}
									</WizardHeaderTag>
								)
							}
						</Box>
					</RuleSummary>
				</Grid>
			</Stack>
		</WizardStepContainer>
	);
}