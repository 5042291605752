import { TextField } from "@mui/material";

export default function CoAppTextField(props) {
	return (
		<TextField
			variant="standard"
			margin="normal"
			{...props}
		/>
	);
}