import { styled } from "@mui/material/styles";

export const SessionValidationCardStructure = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(5),
	textAlign: "left",
	color: theme.palette.text.secondary,
	boxShadow: "0 1px 3px grey",
	borderRadius: "5px",
	position: "relative",
	margin: "auto",
	maxWidth: "50%",
	[theme.breakpoints.up(1700)]: {
		width: "25%"
	},
	[theme.breakpoints.down(1700)]: {
		width: "30%"
	},
}));