import ThreeDotMenu from "../global/components/three-dot-menu";

import { TagManagementOption } from "./styled/tag-management.styled";
import TagEditOrCreateTextField from "./tag-edit-or-create-textfield";

export default function TagItem(props) {
	const {
		tag, editTagHandler, deleteTagHandler,
		editedTagName, editedTagNameError, handleEditedTagNameChange,
		handleCancelEditClick, tagBeingEdited, editTagConfirmationHandler
	} = props;

	return (
		<div style={{ position: "relative" }}>

			{
				tagBeingEdited.name !== "" && tagBeingEdited.name === tag.name ?
					<TagEditOrCreateTextField
						value={editedTagName}
						valueError={editedTagNameError}
						handleValueChange={handleEditedTagNameChange}
						handleConfirmClick={editTagConfirmationHandler}
						handleCancelClick={handleCancelEditClick}
						editedTag={tag}
					/>
					:
					<TagManagementOption>
						{tag.name}
						<ThreeDotMenu
							options={[
								{
									name: "Edit",
									optionClickHandler: () => {
										editTagHandler(tag);
									}
								},
								{
									name: "Delete",
									optionClickHandler: () => {
										deleteTagHandler(tag);
									}
								}
							]}
						/>
					</TagManagementOption>
			}
		</div>
	);
}