import { CircularProgress, Dialog } from "@mui/material";

import {
	ScimWizardEmbeddedVideoContainer, ScimWizardEmbeddedVideoIframe, ScimWizardEmbeddedVideoLoadingContainer,
	ScimWizardHelpIcon, ScimWizardPlayArrow, ScimWizardThumbnailTooltip
} from "./styled/scim-wizard.styled";

export default function ScimWizardEmbeddedVideo(props) {
	const {
		showVideo, setShowVideo, showThumbnail,
		setShowThumbnail, iframeTitle, iframeSrc,
		thumbnailSrc, thumbnailAltText, iconCustomStyles = {}
	} = props;

	const handleShowVideo = () => {
		setShowVideo(true);
		setShowThumbnail(false);
	};

	return (
		<>
			<ScimWizardThumbnailTooltip
				open={showThumbnail}
				onOpen={() => setShowThumbnail(true)}
				onClose={() => setShowThumbnail(false)}
				title={
					<div style={{ position: "relative" }}>
						<ScimWizardPlayArrow onClick={handleShowVideo} />
						<img style={{ maxWidth: "300px" }} src={thumbnailSrc} alt={thumbnailAltText} />
					</div>
				}
			>
				<ScimWizardHelpIcon onClick={handleShowVideo} sx={iconCustomStyles} />
			</ScimWizardThumbnailTooltip>
			<Dialog open={showVideo} onClose={() => setShowVideo(false)} fullWidth={true} maxWidth="1000px">
				<ScimWizardEmbeddedVideoContainer>
					<ScimWizardEmbeddedVideoLoadingContainer>
						<CircularProgress />
					</ScimWizardEmbeddedVideoLoadingContainer>
					<ScimWizardEmbeddedVideoIframe
						title={iframeTitle}
						src={iframeSrc}
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					/>
				</ScimWizardEmbeddedVideoContainer>
			</Dialog>
		</>
	);
}