import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messages from "../../../constants/messages";
import useAuthHeader from "../../../hooks/useAuthHeader";
import {
	selectDescription, selectEditedVersionOfRule, selectName,
	selectTags, setDescription, setName, setTags
} from "../../../redux/ruleWizardSlice";
import CoAppTextField from "../../global/components/inputs/coapp-textfield";
import TagInput from "../../tags/tag-input";

export default function WizardStep1(props) {
	const dispatch = useDispatch();
	let authHeader = useAuthHeader();

	const editedVersionOfRule = useSelector(selectEditedVersionOfRule);
	const currentName = useSelector(selectName);
	const currentDesc = useSelector(selectDescription);
	const currentTags = useSelector(selectTags);
	const [tagsInCurrentOrganization, setTagsInCurrentOrganization] = useState([]);
	const [hasLoaded, setHasLoaded] = useState(false);

	const initExistingTags = () => {
		if (!hasLoaded) {
			axios.get(apiRoutes.getTags, {
				headers: authHeader
			}).then(res => {
				setTagsInCurrentOrganization(res.data);
			}).catch(err => {
				console.error(err);
			});
		}
		setHasLoaded(true);
	};

	const handleFieldChange = (field, setFieldAction, maxLength) => (e) => {
		let value = e.target.value;
		if (value.length > maxLength) {
			props.stepOneErrorChangeHandler({
				...props.errors,
				[`${field}Error`]: true,
				[`${field}ErrorMessage`]: messages.TOO_MANY_CHARACTERS_ERROR_MSG(maxLength, value.length - maxLength)
			});
			dispatch(setFieldAction(value));
		} else {
			delete props.errors[`${field}Error`];
			delete props.errors[`${field}ErrorMessage`];
			props.stepOneErrorChangeHandler({ ...props.errors });
			dispatch(setFieldAction(value));
		}
	};

	const setCreateTags = (tags) => {
		dispatch(setTags(tags));
		props.handleSaveChanges(null, tags);
	};

	useEffect(() => {
		initExistingTags();
		if (props.editedId && Object.keys(editedVersionOfRule).length > 0) {
			if (currentName !== editedVersionOfRule.name || currentDesc !== editedVersionOfRule.description ||
				currentTags.length !== editedVersionOfRule.tags.length) {
				props.handleStepDataChange(true);
			}
		}
	}, [currentDesc, currentName, currentTags]);

	return (
		<Stack spacing={2} sx={{ marginTop: "150px" }}>
			<Typography variant="h6">Name, describe, and tag the rule</Typography>
			<CoAppTextField
				error={props.errors.nameError}
				fullWidth={false}
				helperText={props.errors.nameErrorMessage ? props.errors.nameErrorMessage : `${props.name.length}/50`}
				label="Name"
				name="ruleName"
				onChange={handleFieldChange("name", setName, 50)}
				placeholder="Name"
				size="medium"
				sx={{ width: "60%" }}
				type="text"
				value={props.name}
			/>

			<CoAppTextField
				error={props.errors.descriptionError}
				fullWidth={false}
				helperText={props.errors.descriptionErrorMessage ? props.errors.descriptionErrorMessage : `${props.description.length}/255`}
				label="Description"
				name="description"
				onChange={handleFieldChange("description", setDescription, 255)}
				placeholder="Description"
				size="medium"
				sx={{ width: "60%" }}
				type="text"
				value={props.description}
			/>

			<TagInput createTags={setCreateTags} textFieldWidth={ "60%" } tags={props.tags} tagsInCurrentOrg={tagsInCurrentOrganization} />
		</Stack>
	);
}