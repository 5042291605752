import { Popover, Typography } from "@mui/material";

import { CoAppCloseIcon } from "../../../global/styled/global.styled";

import { ScimWizardExternalGroupMembersContainer, ScimWizardExternalGroupMembersList } from "./styled/scim-wizard.styled";

export default function ExternalGroupUsersPopover(props) {
	const {
		anchorEl, setAnchorEl, members,
		externalGroupName, membersLoading
	} = props;
	const open = Boolean(anchorEl);
	const id = open ? "external-group-users-popover" : undefined;

	return (
		<Popover
			id={id}
			open={open && !membersLoading}
			anchorEl={anchorEl}
			onClose={() => setAnchorEl(null)}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center"
			}}
			sx={{
				"& .MuiPaper-root": {
					boxShadow: "0px 2px 6px 1px rgba(0, 0, 0, 0.25)",
					borderRadius: "14px"
				}
			}}
		>
			<ScimWizardExternalGroupMembersContainer>
				<CoAppCloseIcon onClick={() => setAnchorEl(null)} top={5} left={323} />
				<Typography variant="subheader">{externalGroupName}</Typography>
				<ScimWizardExternalGroupMembersList>
					<Typography variant="body2" color="text.secondary" width="320px">
						{
							members.length === 0 ? "No members found" : members.join(", ")
						}
					</Typography>
				</ScimWizardExternalGroupMembersList>
			</ScimWizardExternalGroupMembersContainer>
		</Popover>
	);
}