import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	level: "",
	message: "",
	open: false
};

export const alertSlice = createSlice({
	name: "alert",
	initialState,
	reducers: {
		setLevel: (state, action) => {
			state.level = action.payload;
		},
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		setOpen: (state, action) => {
			state.open = action.payload;
		}
	}
});

export const selectMessage = (state) => state.alert.message;
export const selectLevel = (state) => state.alert.level;
export const selectOpen = (state) => state.alert.open;

export const {
	setLevel,
	setMessage,
	setOpen
} = alertSlice.actions;

export default alertSlice.reducer;