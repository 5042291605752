import { useState } from "react";
import { Modal } from "@mui/material";

export default function CoAppDataGridImageCell(props) {
	const { image } = props;
	const [open, setOpen] = useState(false);

	const handleOpen = (e) => {
		e.stopPropagation();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<img
				src={image}
				alt="Preview"
				style={{ cursor: "pointer", maxWidth: "100px" }}
				onClick={handleOpen}
			/>

			<Modal open={open} onClose={handleClose}>
				<div
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						maxWidth: "90%",
						maxHeight: "90%",
						overflow: "auto",
					}}
				>
					<img src={image} alt="Expanded Preview" style={{ width: "100%" }} />
				</div>
			</Modal>
		</div>
	);
}
