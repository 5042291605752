import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { CoAppCancelTextButton, CoAppCloseIcon, CoAppDestructiveActionButton, CoAppDialog } from "../../styled/global.styled";

export default function CoAppDestructiveConfirmationModal(props) {
	const { dialogOpen, dialogTitle, dialogMessage, confirmClickHandler, cancelClickHandler, actionText } = props;
	return (
		<CoAppDialog open={dialogOpen} onClose={cancelClickHandler} PaperProps={{ sx: { borderRadius: "8px", padding: "8px" } }}>
			<div>
				<DialogTitle variant="h6">{dialogTitle}</DialogTitle>
				<CoAppCloseIcon onClick={cancelClickHandler} />
			</div>
			<DialogContent>
				<DialogContentText variant="body1" color="textPrimary">{dialogMessage}</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ marginRight: "15px", marginBottom: "10px" }}>
				<CoAppCancelTextButton onClick={cancelClickHandler}>Cancel</CoAppCancelTextButton>
				<CoAppDestructiveActionButton onClick={confirmClickHandler}>{actionText}</CoAppDestructiveActionButton>
			</DialogActions>
		</CoAppDialog>
	);
}	