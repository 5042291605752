import { forwardRef } from "react";
import { IconButton } from "@mui/material";

const CoAppBulkActionGroupIcon = forwardRef(function CoAppBulkActionGroupIcon(props, ref) {
	const { onClick } = props;
	return (
		<IconButton onClick={onClick} ref={ref} {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<g clipPath="url(#clip0_1707_11424)">
					<path d="M7.57894 12C9.67262 12 11.3684 10.21 11.3684 8C11.3684 5.79 9.67262 4 7.57894 4C5.48525 4 3.78947 5.79 3.78947 8C3.78947 10.21 5.48525 12 7.57894 12Z" fill="#1D38BD" />
					<path d="M7.57894 13C5.04947 13 0 14.34 0 17V20H15.1579V17C15.1579 14.34 10.1084 13 7.57894 13Z" fill="#1D38BD" />
					<path d="M10.9042 4.05C11.7758 5.11 12.3158 6.49 12.3158 8C12.3158 9.51 11.7758 10.89 10.9042 11.95C12.761 11.7 14.2105 10.04 14.2105 8C14.2105 5.96 12.761 4.3 10.9042 4.05Z" fill="#1D38BD" />
					<path d="M14.7126 13.83C15.5558 14.66 16.1053 15.7 16.1053 17V20H18V17C18 15.55 16.4937 14.49 14.7126 13.83Z" fill="#1D38BD" />
					<path d="M22 9V7H20V9H18V11H20V13H22V11H24V9H22Z" fill="#1D38BD" />
					<path d="M22.6 16H20.8H19V17.8H20.8H22.6H24.4V16H22.6Z" fill="#1D38BD" />
				</g>
				<defs>
					<clipPath id="clip0_1707_11424">
						<rect width="24" height="24" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</IconButton>
	);
});

export default CoAppBulkActionGroupIcon;