import React from "react";
import { AppBar, Button, Dialog, DialogContent, IconButton, Slide, TextField, Toolbar, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../hooks/useAuthHeader";

function LocationCreationForm(props) {
	const authHeader = useAuthHeader();
	const [isOpen, setIsOpen] = React.useState(false);
	const [newLocation, setNewLocation] = React.useState({});

	const CreateDialogTransition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	});

	const handleCreateDialogClose = () => {
		window.location.reload();
		setIsOpen(false);
	};

	const handleSaveNewLocation = () => {
		axios.post(apiRoutes.createRuleLocation, newLocation, {
			headers: authHeader
		})
			.then(res => {
				console.log(res);
				window.location.reload();
				setIsOpen(false);
			});
	};

	const setLocationValue = (e) => {
		let location = newLocation;
		location[e.target.id] = e.target.value;
		setNewLocation(location);
	};

	React.useEffect(() => {
		setIsOpen(props.isOpen.createDialogOpen);
	}, [props.isOpen.createDialogOpen]);

	return (
		<>
			<Dialog
				fullScreen
				disableEnforceFocus
				disableAutoFocus
				disableEscapeKeyDown
				open={isOpen}
				onClose={handleCreateDialogClose}
				TransitionComponent={CreateDialogTransition}
			>
				<AppBar sx={{ position: "relative", backgroundColor: "#416856" }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCreateDialogClose}
							aria-label="close"
						>
							<GridCloseIcon />
						</IconButton>

						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Create a new field
						</Typography>

						<Button autoFocus color="inherit" onClick={handleSaveNewLocation}>
							save
						</Button>
					</Toolbar>
				</AppBar>

				<DialogContent>
					<TextField autoFocus margin="dense" id="name" label="Name" type="text"
						fullWidth variant="standard" onChange={setLocationValue} />
					<TextField autoFocus margin="dense" id="pageUrl" label="Page URL" type="text"
						fullWidth variant="standard" onChange={setLocationValue} />
					<TextField autoFocus margin="dense" id="description" label="Description" type="text"
						fullWidth variant="standard" onChange={setLocationValue} />
					<TextField autoFocus margin="dense" id="multiStepIdentifier" label="Multi Step Identifier" type="text"
						fullWidth variant="standard" onChange={setLocationValue} />
					<TextField autoFocus margin="dense" id="previewUrl" label="Preview URL" type="text"
						fullWidth variant="standard" onChange={setLocationValue} />
				</DialogContent>
			</Dialog>
		</>
	);
}

export default LocationCreationForm;
