import { Divider, Stack, Typography } from "@mui/material";

import messages from "../../../../../constants/messages";
import ScimWizardConfirmList from "../scim-wizard-confirm-list";
import { ScimWizardStepContainer } from "../styled/scim-wizard.styled";

export default function Step7(props) {
	const { groupMappings, roleMappings, identityProviderName } = props;

	const mappedGroups = Object.keys(groupMappings).filter(group => groupMappings[group].length > 0);
	const unmappedGroups = Object.keys(groupMappings).filter(group => groupMappings[group].length === 0);
	const mappedRoles = Object.keys(roleMappings).filter(role => roleMappings[role].length > 0);
	const unmappedRoles = Object.keys(roleMappings).filter(role => roleMappings[role].length === 0);

	return (
		<ScimWizardStepContainer spacing={4}>
			<Typography variant="h3_5">Confim Setup</Typography>
			<Typography variant="body1">{messages.SCIM_CONFIRM_SETUP_MSG(identityProviderName)}</Typography>
			<Stack direction="row" spacing={4} height={{ xxl: "445px", lg: "350px" }}>
				<ScimWizardConfirmList
					mapped={mappedRoles}
					unmapped={unmappedRoles}
					resource="Roles"
				/>
				<Divider orientation="vertical" flexItem height="100%" />
				<ScimWizardConfirmList
					mapped={mappedGroups}
					unmapped={unmappedGroups}
					resource="Groups"
				/>
			</Stack>
		</ScimWizardStepContainer>
	);
}