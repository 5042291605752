import { useDispatch } from "react-redux";

import { setLevel, setMessage, setOpen } from "../redux/alertSlice";

export default function useToastAlert() {
	const dispatch = useDispatch();

	const handleToastAlert = (level, message) => {
		dispatch(setOpen(true));
		dispatch(setLevel(level));
		dispatch(setMessage(message));
	};

	return { handleToastAlert };
}