import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FilterAlt, Info } from "@mui/icons-material";
import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { GridPagination, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-premium";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messages from "../../../constants/messages";
import useAuthHeader from "../../../hooks/useAuthHeader";
import { selectTriggers } from "../../../redux/ruleWizardSlice";
import CoAppDataGridImageCell from "../../global/components/datagrid/coapp-datagrid-image-cell";
import DataGridCustomNoRowsOverlay from "../../global/components/datagrid/datagrid-custom-norows-overlay";
import { CoAppDataGridPaginationContainer, CoAppDataGridToolbar } from "../../global/styled/global.styled";
import { RuleWizardDataGrid, WizardStepContainer } from "../styled/rule-wizard.styled";
import RuleTriggerSidebar from "../triggers/trigger-sidebar";

/**
 * Step 3 in Rule Creation Wizard
 * User creates triggers based on locations and fields in the UI
 * 1. User selects a Location for 1+ triggers
 * 2. User creates 1+ triggers in relation to the selected location
 * 3. User can select another location for more triggers...
 */
export default function WizardStep3(props) {
	let authHeader = useAuthHeader();

	const [locations, setLocations] = useState([]);
	const [locationsLoaded, setLocationsLoaded] = useState(false);

	const [location, setLocation] = useState({});

	const [triggerFields, setTriggerFields] = useState([]);

	const triggers = useSelector(selectTriggers);

	const initLocations = async () => {
		await axios.get(apiRoutes.getRuleLocationsForWizard, {
			headers: authHeader,
			params: { ruleId: props.editedId }
		}).then(res => {
			setLocations(res.data);
			setLocationsLoaded(true);
			initTriggerCount(res.data.pageOfItems);
		}).catch(err => {
			console.log(err);
		});
	};

	/**
	 * initTriggerCount after editing triggers and returning to locations
	 * If the location contains a trigger, display the location with fancy border
	 */
	const initTriggerCount = (loadedLocations) => {
		let locationArr = [];
		if (loadedLocations && loadedLocations.length) {
			locationArr = loadedLocations;
		} else {
			locationArr = locations;
		}
		if (locationArr.length > 0 && triggers && triggers.length > 0) {
			let filteredArr = locationArr;
			for (let trigger of triggers) {
				for (let location of locationArr) {
					if (trigger.location.id === location.id) {
						let locationUpdated = JSON.parse(JSON.stringify(location));
						locationUpdated.triggerCount = 1;
						var index = locationArr.findIndex(el => el.id === location.id);
						filteredArr = filteredArr.filter(function (el) { return el.id !== location.id; });
						filteredArr.splice(index, 0, locationUpdated);
						filteredArr.join();
						setLocations(filteredArr);
					}
				}
			}
		} else if (triggers.length === 0 && locationArr.length > 0) {
			let updatedLocations = locationArr.map(location => {
				let tempLoc = location;
				if ("triggerCount" in tempLoc) {
					delete tempLoc.triggerCount;
				}
				return tempLoc;
			});
			setLocations(updatedLocations);
		}
	};

	const setSelectedLocation = (locationId) => {
		const location = locations.filter(location => location.id === locationId)[0];
		setLocation(location);
		initTriggerFields(location);
	};

	const initTriggerFields = (selectedLocation) => {
		axios.get(apiRoutes.ruleFields, {
			headers: authHeader,
			params: { locationId: selectedLocation.id }
		}).then(res => {
			setTriggerFields(res.data);
		}).catch(err => {
			console.log(err);
		});
	};

	function CustomToolbar() {
		return (
			<div style={{ width: "100%" }}>
				<CoAppDataGridToolbar container>
					<Grid item xxl={0.1} xl={0.1} lg={0.2} sm={0.2} />
					<Grid item xxl={1} xl={1} lg={1.7} sm={1.7}>
						<GridToolbarFilterButton />
					</Grid>
					<Grid item xxl={4} xl={4} lg={3.5} sm={3}>
						<GridToolbarQuickFilter sx={{ width: { xxl: "220px", xl: "220px", lg: "200px", sm: "150px" } }} />
					</Grid>
					<CoAppDataGridPaginationContainer item>
						<GridPagination rowsPerPageOptions={[]} />
					</CoAppDataGridPaginationContainer>
				</CoAppDataGridToolbar>
			</div>
		);
	}

	const locationColumns = [
		{ field: "name", flex: 1, headerName: "Location name" },
		{ field: "description", flex: 1, headerName: "Description" },
		{
			field: "previewUrl", flex: 5, headerName: "Preview",
			renderCell: (params) => (
				<CoAppDataGridImageCell image={params.value} />
			)
		},
	];

	useEffect(() => {
		initLocations();
	}, [locationsLoaded, triggers]);

	if (locationsLoaded) {
		return (
			<WizardStepContainer>
				<Stack direction="row" justifyContent={"flex-start"} spacing={1} >
					<>
						<Typography variant="h6">Define variables to trigger reactions</Typography>
						<Tooltip title={messages.TRIGGER_CONFIGURATION_TOOLTIP} placement={"right-end"}>
							<Info sx={{ marginLeft: "10px" }} fontSize="small" />
						</Tooltip>
					</>
				</Stack>
				<Stack direction="row" spacing={1}>
					<RuleWizardDataGrid
						columns={locationColumns}
						getRowClassName={(params) =>
							params.row.triggerCount ? "location-w-trigger" : ""
						}
						onRowSelectionModelChange={(locationId) => {
							setSelectedLocation(locationId[0]);
						}}
						rows={locations}
						sx={{
							cursor: "pointer",
						}}
						slots={{
							toolbar: CustomToolbar,
							noResultsOverlay: DataGridCustomNoRowsOverlay,
							noRowsOverlay: DataGridCustomNoRowsOverlay,
							openFilterButtonIcon: FilterAlt
						}}
						slotProps={{
							noResultsOverlay: { message: "No locations found." },
							noRowsOverlay: { message: "No locations found." }
						}}
						hideFooter
						disableColumnMenu
					/>
					<RuleTriggerSidebar
						location={location}
						triggerFields={triggerFields}
						handleStepDataChange={props.handleStepDataChange}
						editedId={props.editedId}
						handleTriggerFieldLoad={initTriggerFields}
						handleSaveChanges={props.handleSaveChanges}
					/>
				</Stack>
			</WizardStepContainer>
		);
	}
}