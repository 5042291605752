import { useEffect, useState } from "react";
import { Add, Label, Search } from "@mui/icons-material";
import {
	Box, InputAdornment, Popover,
	TextField, Typography, useTheme
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import messages from "../../constants/messages";
import useAuthHeader from "../../hooks/useAuthHeader";
import CoAppIconActionButton from "../global/components/inputs/coapp-icon-action-button";
import CoAppDestructiveConfirmationModal from "../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../global/components/modals/coapp-edit-name-modal";
import { CoAppCloseIcon, CoAppTextButton } from "../global/styled/global.styled";

import { CreateTagContainer, TagContainer } from "./styled/tag-management.styled";
import TagEditOrCreateTextField from "./tag-edit-or-create-textfield";
import TagItem from "./tag-item";

export default function TagManagement({ initRuleLibrary }) {
	const authHeader = useAuthHeader();
	const theme = useTheme();

	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [tags, setTags] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [tagBeingDeleted, setTagBeingDeleted] = useState({ name: "" });
	const [tagBeingEdited, setTagBeingEdited] = useState({ name: "" });
	const [deleteTagModalOpen, setDeleteTagModalOpen] = useState(false);
	const [editExistingTagModalOpen, setEditExistingTagModalOpen] = useState(false);
	const [newNameForEditedExistingTag, setNewNameForEditedExistingTag] = useState("");
	const [editedExistingTagNameError, setEditedExistingTagNameError] = useState("");
	const [collidingTag, setCollidingTag] = useState({ name: "" });
	const [isCreating, setIsCreating] = useState(false);
	const [tagName, setTagName] = useState("");
	const [tagNameError, setTagNameError] = useState("");

	const handleSearchInputChange = (e) => {
		setSearchInput(e.target.value);
	};

	const handleOpenClick = (e) => {
		setAnchorEl(e.currentTarget);
		setOpen(true);
	};

	const handleCloseClick = () => {
		setOpen(false);
		setAnchorEl(null);
		setSearchInput("");
		setTagName("");
		setIsCreating(false);
		setTagNameError("");
		setTagBeingEdited({ name: "" });
		setTagBeingDeleted({ name: "" });
		setCollidingTag({ name: "" });
		setDeleteTagModalOpen(false);
		setEditExistingTagModalOpen(false);
		setNewNameForEditedExistingTag("");
		setEditedExistingTagNameError("");
		setTags(prev => prev.sort((a, b) => a.name.localeCompare(b.name))); // sort tags alphabetically on close
	};

	const resetCreationValues = () => {
		setTagName("");
		setIsCreating(false);
		setTagNameError("");
	};

	const handleCreateTag = () => {
		let newTagData = {
			name: tagName
		};
		setTagNameError("");
		axios.post(apiRoutes.createTag, newTagData, { headers: authHeader })
			.then(() => {
				resetCreationValues();
				initTagManagement(true, tagName);
			})
			.catch(err => {
				if (err.response.data.message.includes("already exists")) {
					setTagNameError(getTagCreationErrorText(tagName));
				} else {
					setTagNameError(err.response.data.message);
				}
				console.log(err);
			});
	};

	const getCollidingTag = (name) => {
		const foundTag = tags.find(tag => tag.name.toLowerCase() === name.toLowerCase());
		return foundTag;
	};

	const updateExistingTag = () => {
		const isEditingCollidingTag = newNameForEditedExistingTag !== "" ? true : false;
		let newTagData = {
			name: isEditingCollidingTag ? newNameForEditedExistingTag : tagName
		};

		const tagToUpdateId = isEditingCollidingTag ? getCollidingTag(tagName).id : tagBeingEdited.id;
		axios.put(apiRoutes.updateTag + "/" + tagToUpdateId, newTagData, { headers: authHeader })
			.then(() => {
				setTagNameError("");
				initTagManagement();
				initRuleLibrary();
				setEditExistingTagModalOpen(false);
				setNewNameForEditedExistingTag("");
				if (!isEditingCollidingTag) {
					setTagName("");
				}
			})
			.catch(err => {
				if (err.response.data.message.includes("already exists")) {
					if (isEditingCollidingTag) {
						setEditedExistingTagNameError(err.response.data.message);
					} else {
						setTagNameError(err.response.data.message);
					}
				}
				console.log(err);
			});
	};

	const handleDeleteTag = () => {
		axios.delete(apiRoutes.deleteTag + "/" + tagBeingDeleted.id, {
			headers: authHeader,
		}).then(() => {
			handleDeleteTagModalToggle();
			initTagManagement();
			initRuleLibrary();
		}).catch(err => {
			console.log(err);
		});
	};

	const initTagManagement = (isCreationEvent = false, tagName = "") => {
		axios.get(apiRoutes.getTags, { headers: authHeader })
			.then(res => {
				let tempTags = res.data;
				if (isCreationEvent) {
					const foundNewTag = res.data.find(tag => tag.name.toLowerCase() === tagName.toLowerCase());
					tempTags = [foundNewTag, ...res.data.filter(tag => tag.name.toLowerCase() !== tagName.toLowerCase())];
					setTags(tempTags);
				} else {
					setTags(res.data);
				}
			})
			.catch(err => console.log(err.message));
	};

	const handleDeleteTagModalToggle = (tag = { name: "" }) => {
		setTagBeingDeleted(tag);
		setDeleteTagModalOpen(!deleteTagModalOpen);
	};

	const handleCreateClick = () => {
		setIsCreating(true);
	};

	const handleEditTagNameClick = (editedTag = { name: "" }) => {
		setTagBeingEdited(editedTag);
	};

	const handleCancelEditClick = () => {
		setTagBeingEdited({ name: "" });
		setTagName("");
		setTagNameError("");
	};

	const handleEditedTagNameChange = (e) => {
		setTagName(e.target.value);
		setTagNameError("");
	};

	const handleEditExistingTagNameModalToggle = () => {
		setEditExistingTagModalOpen(true);
		setCollidingTag(getCollidingTag(tagName));
	};

	const handleEditedExistingTagNameChange = (e) => {
		setNewNameForEditedExistingTag(e.target.value);
	};

	const handleCancelCreateClick = () => {
		setIsCreating(false);
		setTagName("");
		setTagNameError("");
	};

	const handleNewTagNameChange = (e) => {
		setTagName(e.target.value);
		setTagNameError("");
	};

	const getEditExistingLeftPosition = () => {
		if (tagName.length > 15 && tagName.length <= 20) {
			return 140;
		} else {
			return 85;
		}
	};

	const getTagCreationErrorText = (name) => {
		return `Tag "${name}" already exists (tags are case insensitive).`;
	};

	useEffect(() => {
		initTagManagement();
	}, []);

	return (
		<div>
			<CoAppIconActionButton
				icon={<Label sx={{ marginRight: "5px", color: theme.palette.white_states.main, fontSize: "20px" }} />}
				text="Manage Tags"
				onClick={handleOpenClick}
			/>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleCloseClick}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box sx={{ width: "328px", maxHeight: "550px", position: "relative", overflowX: "hidden" }}>
					<CoAppCloseIcon onClick={handleCloseClick} top={5} left={300} />
					<TextField
						placeholder="Search tags"
						value={searchInput}
						variant="standard"
						sx={{ width: "280px", height: "40px", marginTop: "17px", marginLeft: "14px" }}
						onChange={handleSearchInputChange}
						InputProps={{
							startAdornment:
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
						}}
					/>
					{
						isCreating ?
							<div style={{ position: "relative" }}>
								<TagEditOrCreateTextField
									value={tagName}
									valueError={tagNameError}
									handleValueChange={handleNewTagNameChange}
									handleConfirmClick={handleCreateTag}
									handleCancelClick={handleCancelCreateClick}
								/>
								{
									tagNameError !== "" && tagNameError.includes("already exists") ?
										<CoAppTextButton
											disableRipple
											sx={{ position: "absolute", left: getEditExistingLeftPosition(), top: 58, fontSize: "12px", ":hover": { backgroundColor: "transparent" } }}
											onClick={handleEditExistingTagNameModalToggle}
										>
											Edit existing tag
										</CoAppTextButton>
										:
										null
								}
							</div>
							:
							<CreateTagContainer onClick={handleCreateClick}>
								<Add sx={{ color: theme.palette.text.disabled, marginRight: "8px" }} />
								<Typography variant="body1" color="text.disabled">Create new tag</Typography>
							</CreateTagContainer>
					}
					<TagContainer>
						{
							tags
								.filter(tag => tag.name.toLowerCase().includes(searchInput.toLowerCase()))
								.map(tag => (
									<TagItem
										key={tag.id}
										tag={tag}
										deleteTagHandler={handleDeleteTagModalToggle}
										editTagHandler={handleEditTagNameClick}
										editTagConfirmationHandler={updateExistingTag}
										tagBeingEdited={tagBeingEdited}
										editedTagName={tagName}
										editedTagNameError={tagNameError}
										handleEditedTagNameChange={handleEditedTagNameChange}
										handleCancelEditClick={handleCancelEditClick}
									/>
								))
						}
					</TagContainer>
				</Box>
			</Popover>
			<CoAppEditNameModal
				dialogOpen={editExistingTagModalOpen}
				dialogTitle="Edit tag name"
				changeHandler={handleEditedExistingTagNameChange}
				placeholderText={collidingTag.name}
				confirmClickHandler={updateExistingTag}
				cancelClickHandler={() => setEditExistingTagModalOpen(false)}
				actionText="Save"
				editNameError={editedExistingTagNameError}
				setEditNameError={setEditedExistingTagNameError}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteTagModalOpen}
				dialogTitle={`Delete ${tagBeingDeleted.name} tag? `}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(tagBeingDeleted.name, "tag")}
				confirmClickHandler={handleDeleteTag}
				cancelClickHandler={handleDeleteTagModalToggle}
				actionText="Delete"
			/>
		</div>
	);
}