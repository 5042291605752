/*global chrome*/
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Descope, getRefreshToken, getSessionToken, useDescope, useSession, useUser } from "@descope/react-sdk";
import { Logout } from "@mui/icons-material";
import { Box, CircularProgress, Container, Stack, Tooltip } from "@mui/material";
import axios from "axios";
import jwtDecode from "jwt-decode";

import apiRoutes from "../../constants/api-routes";
import { selectUser, setPermissions } from "../../redux/userSlice";
import {
	setEmail, setFirstName, setGroups, setLastName, setOrganizationKillSwitch, setOrgId, setPhone, setRoles, setUserId
} from "../../redux/userSlice";
import { CoAppH4, CoAppLoginContainer, CoAppLoginFlowContainer } from "../global/styled/global.styled";

import { SessionValidationCardStructure } from "./styled/extension.styled";

export default function Extension() {
	const dispatch = useDispatch();
	const { logout } = useDescope();
	const currentUser = useSelector(selectUser);

	const { isAuthenticated, isSessionLoading } = useSession();
	const { isUserLoading } = useUser();

	const handleUserLogout = useCallback(async () => {
		await logout();
		dispatch(setEmail(""));
		dispatch(setUserId(""));
		dispatch(setFirstName(""));
		dispatch(setGroups(""));
		dispatch(setLastName(""));
		dispatch(setPermissions([]));
		dispatch(setPhone(""));
		dispatch(setRoles([]));
		dispatch(setOrgId(""));
	}, [logout]);

	const throwSuccessEvent = () => {
		let sessionToken = getSessionToken();
		let refreshToken = getRefreshToken();
		const decodedJwt = jwtDecode(sessionToken);
		axios.get(apiRoutes.getUser(1, "", currentUser.email, decodedJwt.tenantId ? decodedJwt.tenantId : null), { headers: { "Authorization": "Bearer " + sessionToken } })
			.then(res => {
				dispatch(setFirstName(res.data.firstname));
				dispatch(setLastName(res.data.lastname));
				dispatch(setGroups(res.data.groups));
				dispatch(setUserId(res.data.id));
				dispatch(setPhone(res.data.phone));
				dispatch(setOrganizationKillSwitch(res.data.organizationKillSwitch));
				dispatch(setOrgId(res.data.organizationId));
				dispatch(setPermissions(res.data.permissions));
				dispatch(setRoles(res.data.roles));
				const event = new CustomEvent("extensionAuthenticationComplete", {
					detail: {
						user: { ...currentUser, accessToken: sessionToken, refreshToken: refreshToken },
						permissions: res.data.permissions,
						accessToken: sessionToken,
						refreshToken: refreshToken
					}
				});
				document.dispatchEvent(event);
			})
			.catch(err => {
				console.error(err);
			});
	};

	const handleLoginAndThrowSuccessEvent = (e) => {
		const decodedJwt = jwtDecode(e.detail.sessionJwt);
		let email = decodedJwt.email;
		let tenantId;
		if (decodedJwt.tenantId) {
			tenantId = decodedJwt.tenantId;
		}
		dispatch(setEmail(email));
		axios.get(apiRoutes.getUser(1, "", email, tenantId ? tenantId : null), { headers: { "Authorization": "Bearer " + e.detail.sessionJwt } })
			.then(res => {
				dispatch(setFirstName(res.data.firstname));
				dispatch(setLastName(res.data.lastname));
				dispatch(setGroups(res.data.groups));
				dispatch(setUserId(res.data.id));
				dispatch(setPhone(res.data.phone));
				dispatch(setOrganizationKillSwitch(res.data.organizationKillSwitch));
				dispatch(setOrgId(res.data.organizationId));
				dispatch(setPermissions(res.data.permissions));

				const event = new CustomEvent("extensionAuthenticationComplete",
					{
						detail: {
							user: {
								...currentUser,
								email: email,
								firstName: res.data.firstname,
								lastName: res.data.lastname,
								permissions: res.data.permissions,
								accessToken: e.detail.sessionJwt,
								refreshToken: e.detail.refreshJwt
							},
							accessToken: e.detail.sessionJwt,
							refreshToken: e.detail.refreshJwt
						}
					}
				);
				document.dispatchEvent(event);
			})
			.catch(err => {
				console.error(err);
			});
	};

	useEffect(() => {
		if (!isSessionLoading) {
			if (isAuthenticated) {
				throwSuccessEvent();
			} else {
				handleUserLogout();
			}
		}
	}, [isAuthenticated]);

	if (isAuthenticated) {
		return (
			<SessionValidationCardStructure>
				<img href="/library" src="/images/CoApp-Logo-COLOR-RGB.png" alt="CoApp Logo" style={{ height: "50px" }} />
				<CoAppH4 sx={{ display: "inline", padding: "15px" }}>Thanks for logging in. You can close this tab now.</CoAppH4>
				<Box sx={{ float: "right", flexGrow: 0, margin: "5px", display: "inline" }}>
					<Tooltip title="Logout">
						<Logout onClick={handleUserLogout} sx={{ p: 0 }} />
					</Tooltip>
				</Box>
			</SessionValidationCardStructure>
		);
	} else if (isSessionLoading || isUserLoading) {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	} else {
		return (
			<CoAppLoginContainer>
				<CoAppLoginFlowContainer>
					<Descope
						flowId="sign-in-w-sync-and-ccs"
						onSuccess={handleLoginAndThrowSuccessEvent}
						onError={(e) => console.log("Could not log in!")}
					/>
				</CoAppLoginFlowContainer>
			</CoAppLoginContainer>
		);
	}
}