import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDescope } from "@descope/react-sdk";
import { AppBar, Container } from "@mui/material";

import pages from "../../constants/pages";
import {
	setEmail, setFirstName, setGroups,
	setLastName, setOrgId, setPermissions,
	setPhone, setRoles, setUserId
} from "../../redux/userSlice";
import CoAppProfile from "../global/components/coapp-profile";

export default function CoAppHeader() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { logout } = useDescope();

	const handleUserLogout = useCallback(async () => {
		await logout();
		dispatch(setEmail(""));
		dispatch(setUserId(""));
		dispatch(setFirstName(""));
		dispatch(setGroups([]));
		dispatch(setLastName(""));
		dispatch(setPermissions([]));
		dispatch(setPhone(""));
		dispatch(setRoles([]));
		dispatch(setOrgId(""));
		navigate(pages.login);
	}, [logout]);

	return (
		<AppBar position="static">
			<Container maxWidth="false" disableGutters>
				<CoAppProfile handleLogout={handleUserLogout} />
			</Container>
		</AppBar >
	);
}