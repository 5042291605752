import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	ClickAwayListener, Divider, Grow,
	Stack, Typography
} from "@mui/material";

import pages from "../../../constants/pages";
import useAuthorization from "../../../hooks/useAuthorization";
import { resetNavigationState } from "../../../redux/navigationSlice";
import { selectUser } from "../../../redux/userSlice";
import {
	CoAppEditIcon, CoAppExpandMoreIcon,
	CoAppProfileAvatar, CoAppProfileAvatarContainer, CoAppProfileContentContainer,
	CoAppProfileEmailText, CoAppProfileListText, CoAppProfileLogoutTextButton,
	CoAppProfileNameText, CoAppProfileResourceContainer
} from "../styled/global.styled";

export default function CoAppProfile({ handleLogout }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector(selectUser);

	const [expanded, setExpanded] = useState(false);
	const [rolesExpanded, setRolesExpanded] = useState(false);
	const [groupsExpanded, setGroupsExpanded] = useState(false);
	const groupsShouldExpand = user.groups && user.groups.length > 10;
	const rolesShouldExpand = user.roles && user.roles.length > 10;
	const isAuthorizedForUserManagement = useAuthorization(["MANAGE_USERS_AND_GROUPS"], true);

	const handleLogoutClick = () => {
		dispatch(resetNavigationState());
		handleLogout();
	};

	const handleGroupExpand = () => setGroupsExpanded(!groupsExpanded);

	const handleRoleExpand = () => setRolesExpanded(!rolesExpanded);

	const handleExpand = () => {
		setExpanded(!expanded);
		setRolesExpanded(false);
		setGroupsExpanded(false);
	};

	const getAvatarText = () => {
		if (!user.email) return null;
		if (user.firstName && user.lastName) {
			return user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase();
		} else {
			return user.email[0].toUpperCase();
		}
	};

	const handleUserEditClick = () => {
		setExpanded(false);
		setRolesExpanded(false);
		setGroupsExpanded(false);
		const stateObj = {
			id: user.userId,
			firstname: user.firstName,
			lastname: user.lastName,
			email: user.email
		};
		navigate(pages.profile + user.userId + "?from=u", { state: stateObj });
	};

	const handleClickAway = () => {
		setExpanded(false);
		setRolesExpanded(false);
		setGroupsExpanded(false);
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<div>
				<CoAppProfileAvatarContainer onClick={handleExpand}>
					<CoAppProfileAvatar>
						{getAvatarText()}
					</CoAppProfileAvatar>
					<CoAppExpandMoreIcon onClick={handleExpand} clickedopen={expanded ? "true" : "false"} />
				</CoAppProfileAvatarContainer>
				<Grow in={expanded}>
					<CoAppProfileContentContainer>
						<Stack direction="row" spacing={1} sx={{ margin: "16px" }}>
							<CoAppProfileAvatar h="32px" w="32px">
								{getAvatarText()}
							</CoAppProfileAvatar>
							<CoAppProfileNameText>{user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : null}</CoAppProfileNameText>
							{isAuthorizedForUserManagement ? <CoAppEditIcon onClick={handleUserEditClick} /> : null}
						</Stack>
						<CoAppProfileEmailText>{user.email}</CoAppProfileEmailText>
						<Divider sx={{ marginBottom: "18px" }} />
						<Stack direction="column" spacing={2} sx={{ margin: "8px" }}>
							<CoAppProfileResourceContainer backgroundcolor="#AEEDDB33">
								<Typography variant="subheader" color="text.primary">My Groups</Typography>
								<br />
								{
									user.groups && user.groups.length > 10 && !groupsExpanded ?
										<CoAppProfileListText variant="menuItemDense" color="text.primary" remaindercount={user.groups.length - 10}>
											{user.groups && user.groups.map(group => group.name).slice(0, 10).join(", ")}
										</CoAppProfileListText>
										:
										<CoAppProfileListText variant="menuItemDense" color="text.primary">{user.groups && user.groups.map(group => group.name).join(", ")}</CoAppProfileListText>
								}
								{
									groupsShouldExpand ?
										<CoAppExpandMoreIcon onClick={handleGroupExpand} usefloat="true" clickedopen={groupsExpanded ? "true" : "false"} />
										:
										null
								}
							</CoAppProfileResourceContainer>
							<CoAppProfileResourceContainer backgroundcolor="#0195D51A">
								<Typography variant="subheader" color="text.primary">My Roles</Typography>
								<br />
								{
									user.roles && user.roles.length > 10 && !rolesExpanded ?
										<CoAppProfileListText variant="menuItemDense" color="text.primary" remaindercount={user.roles.length - 10}>
											{user.roles && user.roles.map(role => role).slice(0, 10).join(", ")}
										</CoAppProfileListText>
										:
										<CoAppProfileListText variant="menuItemDense" color="text.primary">{user.roles && user.roles.map(role => role).join(", ")}</CoAppProfileListText>
								}
								{
									rolesShouldExpand ?
										<CoAppExpandMoreIcon onClick={handleRoleExpand} usefloat="true" clickedopen={rolesExpanded ? "true" : "false"} />
										:
										null

								}
							</CoAppProfileResourceContainer>
						</Stack>
						<Divider sx={{ marginTop: "18px" }} />
						<CoAppProfileLogoutTextButton disableRipple={true} onClick={handleLogoutClick}>Log out</CoAppProfileLogoutTextButton>
					</CoAppProfileContentContainer>
				</Grow>
			</div>
		</ClickAwayListener >
	);
}