import { IconButton } from "@mui/material";

export default function CoAppAzureIdpIcon(props) {
	return (
		<IconButton {...props}>

			<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
				<path d="M16.8977 62.3644C18.6221 63.44 21.4888 64.6356 24.5199 64.6356C27.2799 64.6356 29.8444 63.8356 31.9688 62.4711C31.9688 62.4711 31.9733 62.4711 31.9777 62.4667L39.9999 57.4533V75.5556C38.7288 75.5556 37.4488 75.2089 36.3377 74.5156L16.8977 62.3644Z" fill="#225086" />
				<path d="M34.9022 6.69778L1.56887 44.2978C-1.00446 47.2044 -0.333349 51.5956 3.00443 53.68C3.00443 53.68 15.3422 61.3911 16.8978 62.3644C18.6222 63.44 21.4889 64.6356 24.52 64.6356C27.28 64.6356 29.8444 63.8355 31.9689 62.4711C31.9689 62.4711 31.9733 62.4711 31.9778 62.4667L40 57.4533L20.6044 45.3289L40.0044 23.4444V4.44444C38.12 4.44444 36.2355 5.19555 34.9022 6.69778Z" fill="#66DDFF" />
				<path d="M20.6045 45.3289L20.8356 45.4711L40 57.4533H40.0045V23.4489L40 23.4444L20.6045 45.3289Z" fill="#CBF8FF" />
				<path d="M76.9955 53.68C80.3333 51.5956 81.0044 47.2044 78.4311 44.2978L56.5599 19.6267C54.7955 18.8044 52.8177 18.3333 50.7244 18.3333C46.6133 18.3333 42.9377 20.1067 40.4933 22.8933L40.0088 23.44L59.4044 45.3244L40.0044 57.4489V75.5511C41.28 75.5511 42.5511 75.2044 43.6622 74.5111L76.9955 53.6756V53.68Z" fill="#074793" />
				<path d="M40.0044 4.44444V23.4444L40.4889 22.8978C42.9333 20.1111 46.6089 18.3378 50.72 18.3378C52.8178 18.3378 54.7911 18.8133 56.5556 19.6311L45.0933 6.70222C43.7644 5.2 41.88 4.44889 40 4.44889L40.0044 4.44444Z" fill="#0294E4" />
				<path d="M59.3999 45.3289L40.0044 23.4489V57.4489L59.3999 45.3289Z" fill="#96BCC2" />
			</svg>
		</IconButton>
	);
}