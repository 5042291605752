import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";

import messages from "../../../constants/messages";
import {
	selectEditedVersionOfRule, selectReactions, selectRuleLocation,
	setReactions
} from "../../../redux/ruleWizardSlice";
import { CoAppTextButton } from "../../global/styled/global.styled";
import {
	RuleReactionSidebarContainer, RuleSidebarRow, RuleSidebarRowActions
} from "../styled/rule-sidebars.styled";

import RuleReactionDialog from "./rule-reaction-dialog";

export default function RuleReactionSidebar(props) {
	const dispatch = useDispatch();
	const reactions = useSelector(selectReactions);
	const editedVersionOfRule = useSelector(selectEditedVersionOfRule);
	const ruleLocation = useSelector(selectRuleLocation);

	const handleSaveChanges = props.handleSaveChanges;

	const reactionFields = props.reactionFields;

	const [fieldDropdownValue, setFieldDropdownValue] = useState({});
	const [typeDropdownValue, setTypeDropdownValue] = useState("");
	const [reactionText, setReactionText] = useState("");
	const [isPopup, setIsPopup] = useState(false);
	const [popupLocation, setPopupLocation] = useState("");
	const [desc, setDesc] = useState("");
	const [formHasErrors, setFormHasErrors] = useState(false);
	const [userConfirmationText, setUserConfirmationText] = useState("");

	const [newReactions, setNewReactions] = useState(reactions);
	const [editReactionIndex, setEditReactionIndex] = useState([]);

	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);

	const [fieldValueError, setFieldValueError] = useState("");
	const [typeValueError, setTypeValueError] = useState("");
	const [reactionTextValueError, setReactionTextValueError] = useState("");
	const [popupLocationValueError, setPopupLocationValueError] = useState("");
	const [userConfirmationTextValueError, setUserConfirmationTextValueError] = useState("");

	const clearFields = () => {
		setFieldDropdownValue({});
		setTypeDropdownValue("");
		setReactionText("");
		setIsPopup(false);
		setPopupLocation("");
		setDesc("");
		setFieldValueError("");
		setTypeValueError("");
		setReactionTextValueError("");
		setPopupLocationValueError("");
		setUserConfirmationTextValueError("");
		setUserConfirmationText("");
	};

	const validateFields = () => {
		let hasErrors = false;

		if (typeDropdownValue === "") {
			setTypeValueError(messages.FIELD_IS_REQUIRED_ERROR_MSG);
			hasErrors = true;
		} else {
			setTypeValueError("");
		}

		if (typeDropdownValue !== "disable" && reactionText === "") {
			setReactionTextValueError(messages.FIELD_IS_REQUIRED_ERROR_MSG);
			hasErrors = true;
		} else {
			setReactionTextValueError("");
		}

		if (typeDropdownValue === "popup") {
			if (popupLocation === "") {
				setPopupLocationValueError(messages.FIELD_IS_REQUIRED_ERROR_MSG);
				hasErrors = true;
			} else {
				setPopupLocationValueError("");
			}
		}
		if (typeDropdownValue !== "" && !["popup", "userconfirmationpopup", "managerapprovalpopup"].includes(typeDropdownValue)) {
			if (Object.keys(fieldDropdownValue).length === 0 || fieldDropdownValue === "") {
				setFieldValueError(messages.FIELD_IS_REQUIRED_ERROR_MSG);
				hasErrors = true;
			} else {
				setFieldValueError("");
			}
		}
		if (typeDropdownValue === "userconfirmationpopup") {
			if (userConfirmationText === "" || popupLocation === "") {
				setUserConfirmationTextValueError(userConfirmationText === "" ? messages.FIELD_IS_REQUIRED_ERROR_MSG : "");
				setPopupLocationValueError(popupLocation === "" ? messages.FIELD_IS_REQUIRED_ERROR_MSG : "");
				hasErrors = true;
			} else {
				setUserConfirmationTextValueError("");
			}
		}
		if (hasErrors) {
			setFormHasErrors(true);
			return false;
		} else {
			return true;
		}
	};

	const handleClickOpen = () => {
		clearFields();
		setOpen(true);
	};

	const handleCancel = () => {
		clearFields();
		setOpen(false);
		setEditOpen(false);
	};

	const handleEditCancel = () => {
		clearFields();
		setEditOpen(false);
	};

	const handleClickOpenEditDialog = (reaction, index) => {
		setFieldDropdownValue(JSON.stringify(reaction.reactionField));
		setTypeDropdownValue(reaction.reactionType);
		setReactionText(reaction.reactionText);
		setIsPopup(reaction.isPopup);
		setPopupLocation(reaction.popupLocation);
		setDesc(reaction.description);
		setUserConfirmationText(reaction.userConfirmationText);
		setEditReactionIndex(index);
		setEditOpen(true);
	};

	const handleEditSave = () => {
		//validate fields before updating redux
		if (!validateFields()) {
			return;
		} else {
			let updatedReaction = {
				reactionField: JSON.parse(fieldDropdownValue),
				reactionType: typeDropdownValue,
				reactionText: reactionText,
				isPopup: isPopup,
				popupLocation: popupLocation,
				description: desc,
				userConfirmationText: userConfirmationText
			};
			let nr = [...newReactions];
			nr.splice(editReactionIndex, 1, updatedReaction);
			setNewReactions(nr);
			dispatch(setReactions(nr));
			setEditReactionIndex("");
			setEditOpen(false);
			handleSaveChanges(nr);

			if (props.editedId) {
				props.handleStepDataChange(true);
			}
		}
		
	};

	const handleDeleteReaction = (index) => {
		let reactionIndex = index ? index : editReactionIndex;
		let nr = [...newReactions];
		nr.splice(reactionIndex, 1);
		setNewReactions(nr);
		setEditReactionIndex("");
		dispatch(setReactions(nr));
		setEditOpen(false);
		handleSaveChanges(nr);

		if (props.editedId) {
			props.handleStepDataChange(true);
		}
	};

	const handleSave = () => {
		if (!validateFields()) {
			return;
		} else {
			let newReaction = {
				reactionField: Object.keys(fieldDropdownValue).length > 0 ? JSON.parse(fieldDropdownValue) : null,
				reactionType: typeDropdownValue,
				reactionText: reactionText,
				isPopup: isPopup,
				popupLocation: popupLocation,
				description: desc,
				userConfirmationText: userConfirmationText
			};
			let nr = [...newReactions, newReaction];
			setNewReactions([...newReactions, newReaction]);
			dispatch(setReactions(nr));
			setOpen(false);
			handleSaveChanges(nr);
		}
	};

	const handleFieldDropdownSelectionChange = (e) => {
		setFieldDropdownValue(e.target.value);
		if (Object.keys(e.target.value).length === 0) {
			setFieldValueError(messages.FIELD_IS_REQUIRED_ERROR_MSG);
			setFormHasErrors(true);
		} else {
			setFieldValueError("");
			setFormHasErrors(false);
		}
	};

	const handleTypeDropdownSelectionChange = (e) => {
		setTypeDropdownValue(e.target.value);
		setTypeValueError("");
		setFormHasErrors(false);
		if (e.target.value === "popup" || e.target.value === "userconfirmationpopup" || e.target.value === "managerapprovalpopup") {
			setIsPopup(true);
		} else {
			setPopupLocation("");
			setIsPopup(false);
		}
	};

	const handleReactionTextInput = (e) => {
		setReactionText(e.target.value);
		if (e.target.value.length === 0) {
			setFormHasErrors(true);
			setReactionTextValueError(messages.FIELD_IS_REQUIRED_ERROR_MSG);
		} else {
			setFormHasErrors(false);
			setReactionTextValueError("");
		}
	};

	const handleUserConfirmationTextInput = (e) => {
		setUserConfirmationText(e.target.value);
		if (e.target.value.length === 0) {
			setFormHasErrors(true);
			setUserConfirmationTextValueError(messages.FIELD_IS_REQUIRED_ERROR_MSG);
		} else {
			setFormHasErrors(false);
			setUserConfirmationTextValueError("");
		}
	};

	const handleDescriptionInput = (e) => {
		setDesc(e.target.value);
	};

	const handlePopupLocationSelectionChange = (e) => {
		setPopupLocation(e.target.value);
		setPopupLocationValueError("");
		setFormHasErrors(false);
	};

	useEffect(() => {
		if (props.editedId && Object.keys(editedVersionOfRule).length > 0) {
			if (editedVersionOfRule.ruleLocation) {
				if (ruleLocation.id !== editedVersionOfRule.ruleLocation.id || reactions.length !== editedVersionOfRule.rulereactions.length) {
					props.handleStepDataChange(true);
				}
			}
		}
	}, [ruleLocation, reactions]);

	return (
		<div>
			<RuleReactionSidebarContainer>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Typography sx={{ padding: "5px"}} variant="body1">Reactions</Typography>
					<CoAppTextButton onClick={handleClickOpen}><Add/></CoAppTextButton>
				</Box>
				<Divider sx={{ width: "100%" }} />
				<Stack alignItems="flexStart" direction="column" spacing="0.5" sx={{ maxHeight: "360px", overflow: "auto" }}>
					{
						newReactions.map((reaction, index) =>
							<div key={index}>
								<RuleSidebarRow>
									<div>
										{reaction.reactionField !== null ?
											<Typography sx={{ fontWeight: "600" }} variant="body2">Field: {reaction.reactionField.name}</Typography>
											:
											null
										}
										<Typography variant="body2">Reaction: {props.reactionTypes.filter(type => type.name === reaction.reactionType)[0].friendlyName}</Typography>
									</div>
									<RuleSidebarRowActions alignItems="flexStart" direction="row">
										<Edit onClick={() => handleClickOpenEditDialog(reaction, index)} sx={{ fontSize: "medium", color: "#1D38BD", marginRight: "10px" }} />
										<Delete onClick={() => handleDeleteReaction(index)} sx={{ fontSize: "medium", color: "#1D38BD" }} />
									</RuleSidebarRowActions>
								</RuleSidebarRow>
								<Divider sx={{ width: "100%" }} />
							</div>
						)
					}
				</Stack>
			</RuleReactionSidebarContainer>

			<RuleReactionDialog
				isEdit={editOpen}
				isOpen={open}
				dialogCancelHandler={handleCancel}
				fieldValue={fieldDropdownValue}
				fieldDropdownValueChangeHandler={handleFieldDropdownSelectionChange}
				reactionFields={reactionFields}
				reactionText={reactionText}
				userConfirmationText={userConfirmationText}
				reactionDesc={desc}
				typeValue={typeDropdownValue}
				typeDropdownValueChangeHandler={handleTypeDropdownSelectionChange}
				popupLocation={popupLocation}
				popupLocationValueChangeHandler={handlePopupLocationSelectionChange}
				reactionTextInputHandler={handleReactionTextInput}
				reactionDescriptionInputHandler={handleDescriptionInput}
				userConfirmationTextInputHandler={handleUserConfirmationTextInput}
				saveReactionHandler={handleSave}
				editSaveReactionHandler={handleEditSave}
				deleteReactionHandler={handleDeleteReaction}
				editCancelHandler={handleEditCancel}
				fieldValueError={fieldValueError}
				typeValueError={typeValueError}
				reactionTextValueError={reactionTextValueError}
				formHasErrors={formHasErrors}
				popupLocationValueError={popupLocationValueError}
				userConfirmationTextValueError={userConfirmationTextValueError}
				isLocationIframe={ruleLocation.iframe}
				reactionTypes={props.reactionTypes}
				currentReactions={reactions}
			/>
		</div>
	);
}