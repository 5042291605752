import { Grid, styled, Typography } from "@mui/material";

export const RuleLibraryFilterContainer = styled(Grid)(({ theme }) => ({
	marginTop: "10px",
	marginLeft: "20px",
	marginRight: "25px",
	[theme.breakpoints.up(1700)]: {
		marginLeft: "45px",
		marginRight: "77px"
	}
}));

export const RuleLibraryPaginationContainer = styled("div")(({ theme }) => ({
	display: "flex",
	marginLeft: "375px",
	[theme.breakpoints.up(1700)]: {
		marginLeft: "475px"
	},
	padding: "15px"
}));

export const RuleLibraryTotalRowsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	padding: "15px"
}));

export const RuleLibraryPaginationTotalRows = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	padding: "5px"
}));

export const RuleLibraryLongTextCell = styled("div")(({ theme, overflowing = "false", before = "false", trashed = "false" }) => {
	if (overflowing === "true") {
		let styles = {
			fontSize: theme.typography.body2.fontSize,
			height: "50px",
			alignContent: "center",
			"&::after": {
				content: "'...'",
				letterSpacing: "0.5px"
			},
			lineHeight: "16px",
			textWrap: "wrap",
			color: trashed === "true" ? theme.palette.text.disabled : theme.palette.text.primary
		};
		if (before === "true") {
			styles = {
				...styles,
				"&::before": {
					content: "'Draft '",
					letterSpacing: "0.17px",
					color: theme.palette.error.main,
					fontWeight: 700,
					fontSize: theme.typography.body2.fontSize
				}
			};
		}
		return styles;
	} else {
		let styles = {
			fontSize: theme.typography.body2.fontSize,
			textWrap: "wrap",
			height: "50px",
			alignContent: "center",
			lineHeight: "16px",
			color: trashed === "true" ? theme.palette.text.disabled : theme.palette.text.primary
		};
		if (before === "true") {
			styles = {
				...styles,
				"&::before": {
					content: "'Draft '",
					letterSpacing: "0.17px",
					color: theme.palette.error.main,
					fontWeight: 700,
					fontSize: theme.typography.body2.fontSize
				}
			};
		}
		return styles;
	}
});