import { useLayoutEffect, useRef, useState } from "react";

import { CoAppLightTooltip } from "../global/styled/global.styled";

import { RuleLibraryLongTextCell } from "./styled/rule-library.styled";

export default function RuleDataGridLongTextCell(props) {
	const { value, hasBeforeEffect = false, isTrashed = false } = props;
	const [isOverflowing, setIsOverflowing] = useState(false);
	const [localValue, setLocalValue] = useState(value);
	const ref = useRef();

	/**
	 * Allows us to clip the text shown in our description cells based on string length and window width.
	 * Note: This allows us to use multiline text until an ellipsis is needed to indicate overflow.
	 */
	const getSliceCount = () => {
		const windowWidth = window.innerWidth;
		if (windowWidth > 1800 && value.length > 75) {
			return 75;
		} else if (windowWidth > 1600 && windowWidth <= 1800 && value.length > 65) {
			return 65;
		} else if (windowWidth > 1400 && windowWidth <= 1600 && value.length > 40) {
			return 40;
		} else if (windowWidth > 1100 && windowWidth <= 1300 && value.length > 30) {
			return 30;
		} else {
			return null;
		}
	};

	const handleLayoutEffect = () => {
		if (ref?.current?.clientHeight < ref?.current?.scrollHeight) {
			setIsOverflowing(true);
			const substringCount = getSliceCount();
			setLocalValue(substringCount ? value.substring(0, getSliceCount()) : value);
		} else if (isOverflowing) {
			const substringCount = getSliceCount();
			setLocalValue(substringCount ? value.substring(0, getSliceCount()) : value);
		} else {
			setLocalValue(value);
		}
	};

	useLayoutEffect(() => {
		handleLayoutEffect();
	}, [isOverflowing, window.innerWidth]);

	return (
		<div>
			{
				!isOverflowing ?
					<RuleLibraryLongTextCell
						ref={ref}
						before={hasBeforeEffect ? "true" : "false"}
						trashed={isTrashed ? "true" : "false"}
					>
						{value}
					</RuleLibraryLongTextCell>
					:
					<CoAppLightTooltip title={value} opacity="false">
						<RuleLibraryLongTextCell
							ref={ref}
							overflowing={isOverflowing ? "true" : "false"}
							before={hasBeforeEffect ? "true" : "false"}
							trashed={isTrashed ? "true" : "false"}
						>
							{localValue}
						</RuleLibraryLongTextCell>
					</CoAppLightTooltip>
			}
		</div>
	);
}