import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import navigationItemReferences from "../../../constants/navigation-item-references";
import { getDataGridFakeData } from "../../../helpers/getDataGridFakeData";
import useAuthHeader from "../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../../hooks/useDatagridSorting";
import useToastAlert from "../../../hooks/useToastAlert";
import { selectOrganizationIdentityProvider, selectOrganizationSsoAndScim } from "../../../redux/userSlice";
import CoAppDatagridHeader from "../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppDataGridNameCell from "../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppDataGridSkeletonCell from "../../global/components/datagrid/coapp-datagrid-skeleton-cell";
import CoAppStandardDataGrid from "../../global/components/datagrid/coapp-standard-datagrid";
import CoAppCreateGroupOrRoleModal from "../../global/components/modals/coapp-create-group-or-role-modal";
import CoAppDestructiveConfirmationModal from "../../global/components/modals/coapp-destructive-confirmation-modal";
import ThreeDotMenu from "../../global/components/three-dot-menu";

const groupColumns = ["actions", "name", "users", "rules", "plugins"];
const ssoAndScimEnabledGroupColumns = ["actions", "name", "users", "rules", "idpGroups", "plugins"];

export default function GroupManagement() {
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const dummyRows = getDataGridFakeData(organizationSsoAndScim ? ssoAndScimEnabledGroupColumns : groupColumns);
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("groups");
	const { openResourceEditPage } = useCoAppNavigation();
	const identityProviderName = useSelector(selectOrganizationIdentityProvider);

	const [groupSeed, setGroupSeed] = useState(0);
	const [organizationGroups, setOrganizationGroups] = useState(dummyRows);
	const [groupCreateDialogOpen, setGroupCreateDialogOpen] = useState(false);
	const [groupDeleteDialogOpen, setGroupDeleteDialogOpen] = useState(false);
	const [groupBeingDeleted, setGroupBeingDeleted] = useState({ name: "" });
	const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
	const [groupNameError, setGroupNameError] = useState("");
	const [groupName, setGroupName] = useState("");
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [loading, setLoading] = useState(true);

	const columns = [
		{
			field: "name",
			headerName: "Name",
			sortable: true,
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={params.row} />}
				/>
			),
		},
		{
			field: "users",
			headerName: "Users",
			editable: false,
			sortable: false,
			flex: .5,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridListCell items={params.value} resource="Users" isUser={true} />
					}
				/>
			)
		},
		{
			field: "rules",
			headerName: "Rules",
			editable: false,
			sortable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridListCell items={params.value} resource="Rules" width={475} />
					}
				/>),
		},
		{
			field: "plugins",
			headerName: "Plugins",
			editable: false,
			sortable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridListCell items={params.value} resource="Plugins" />
					}
				/>
			),
		},
		...(organizationSsoAndScim
			? [
				{
					field: "idpgroups",
					headerName: identityProviderName ? `${identityProviderName} Groups` : "Groups",
					editable: false,
					sortable: false,
					flex: 1,
					renderCell: (params) => (
						<CoAppDataGridSkeletonCell
							cellValue={params.value}
							cellToRender={<CoAppDataGridListCell items={params.value} resource={identityProviderName ? `${identityProviderName} Groups` : "Groups"} />}
						/>
					),
				},
			]
			: []),
		{
			field: "actions",
			type: "actions",
			sortable: false,
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<div>
							<ThreeDotMenu
								options={[
									{
										name: "Edit",
										optionClickHandler: () => {
											openResourceEditPage(params, navigationItemReferences.groupNavItemIndex, "Groups");
										},
									},
									{
										name: "Duplicate",
										optionClickHandler: () => {
											duplicateGroup(params.row.id);
										},
									},
									{
										name: "Delete",
										optionClickHandler: () => {
											setGroupDeleteDialogOpen(true);
											setGroupBeingDeleted(params.row);
										},
									},
								]}
							/>
						</div>
					}
				/>
			),
		}
	];

	const createNewGroup = () => {
		if (!groupName) {
			setGroupNameError("Required *");
			return;
		}

		let groupJSON = {
			"groupName": groupName,
		};

		axios.post(apiRoutes.createGroup, groupJSON, { headers: authHeader })
			.then(() => {
				setGroupSeed(Math.random());
				setGroupCreateDialogOpen(false);
				setGroupName("");
				setGroupNameError("");
				handleToastAlert(messageLevels.SUCCESS, messages.GROUP_CREATED_SUCCESS_MSG);
			})
			.catch((err) => {
				if (err.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				handleToastAlert(messageLevels.ERROR, messages.GROUP_CREATION_ERROR_MSG);
			});
	};

	const handleToggleGroupCreateDialog = () => {
		setGroupCreateDialogOpen(!groupCreateDialogOpen);
		setGroupName("");
		setGroupNameError("");
	};

	const handleGroupNameChange = (e) => {
		setGroupName(e.target.value);
		setGroupNameError("");
	};

	const deleteGroup = () => {
		axios
			.delete(apiRoutes.deleteGroup + "/" + groupBeingDeleted.id, {
				headers: authHeader,
			})
			.then(() => {
				handleToastAlert(messageLevels.INFO, messages.GROUP_DELETED_SUCCESS_MSG(false));
				setGroupSeed(Math.random());
				handleToggleGroupDeleteDialog();
			})
			.catch((err) => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.GROUP_DELETION_ERROR_MSG);
				handleToggleGroupDeleteDialog();
			});
	};

	const bulkDeleteGroups = () => {
		const deleteData = {
			groupIds: selectedGroups
		};
		axios.delete(apiRoutes.bulkDeleteGroups, { headers: authHeader, data: deleteData })
			.then(() => {
				handleToastAlert(messageLevels.INFO, messages.GROUP_DELETED_SUCCESS_MSG(selectedGroups.length > 1));
				setGroupSeed(Math.random());
				setBulkDeleteDialogOpen(false);
			})
			.catch((err) => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.GROUP_DELETION_ERROR_MSG);
				setBulkDeleteDialogOpen(false);
			});
	};

	const duplicateGroup = (groupId) => {
		let group = organizationGroups.find((group) => group.id === groupId);
		let groupJson = {
			"name": group.name + " (copy)" + Math.floor(Math.random() * 1000),
		};
		axios
			.post(apiRoutes.copyGroup + "/" + groupId, groupJson, { headers: authHeader })
			.then(() => {
				setGroupSeed(Math.random());
			})
			.catch((err) => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.GROUP_DUPLICATION_ERROR_MSG);
			});
	};

	const handleToggleGroupDeleteDialog = (group = { name: "" }) => {
		setGroupDeleteDialogOpen(!groupDeleteDialogOpen);
		setGroupBeingDeleted(group);
	};

	const initGroupManagement = () => {
		axios.get(apiRoutes.getGroups, { headers: authHeader })
			.then((res) => {
				setOrganizationGroups(res.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		initGroupManagement();
		handleInitializeSortModel();
	}, [groupSeed]);

	return (
		<>
			<CoAppDatagridHeader
				title="Groups"
				resourceType="Group"
				addOnClickHandler={handleToggleGroupCreateDialog}
				addIsPresent={true}
				editIsPresent={false}
				deleteIsPresent={false}
			/>
			<CoAppStandardDataGrid
				rows={organizationGroups}
				columns={columns}
				pinnedColumns={["actions", "__check__", "name"]}
				targetResource="groups"
				selectionModel={selectedGroups}
				setSelectionModel={setSelectedGroups}
				handleModalToggle={() => setBulkDeleteDialogOpen(prev => !prev)}
				loading={loading}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
			/>
			<CoAppCreateGroupOrRoleModal
				dialogOpen={groupCreateDialogOpen}
				changeHandler={handleGroupNameChange}
				confirmClickHandler={createNewGroup}
				cancelClickHandler={handleToggleGroupCreateDialog}
				nameError={groupNameError}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={groupDeleteDialogOpen}
				dialogTitle={`Delete ${groupBeingDeleted.name} group?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(groupBeingDeleted.name, "group")}
				confirmClickHandler={deleteGroup}
				cancelClickHandler={handleToggleGroupDeleteDialog}
				actionText="Delete"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={bulkDeleteDialogOpen}
				dialogTitle={`Delete selected ${selectedGroups.length > 1 ? "groups" : "group"}?`}
				dialogMessage={"Are you sure you want to bulk delete all the selected groups? This action cannot be undone."}
				confirmClickHandler={bulkDeleteGroups}
				cancelClickHandler={() => setBulkDeleteDialogOpen(false)}
				actionText="Delete"
			/>
		</>
	);
}