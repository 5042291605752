import { useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Chip, CircularProgress, } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import { selectOrganizationId } from "../../../../redux/userSlice";
import CoAppMultiSelect from "../../../global/components/inputs/coapp-multiselect";
import { CoAppExpandMoreIcon } from "../../../global/styled/global.styled";

import ExternalGroupUsersPopover from "./external-group-users-popover";

export default function IdpGroupAutocompleteCell(props) {
	const {
		mapped, setMapped, options,
		resourceName, apiRef, validateSuperAdminRoleIsMapped,
		isSuperAdmin, initializeGroupOptions, setExternalGroups
	} = props;

	const authHeader = useAuthHeader();
	const organizationId = useSelector(selectOrganizationId);
	const [idpGroupMembers, setIdpGroupMembers] = useState([]);
	const [idpGroupName, setIdpGroupName] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [membersLoading, setMembersLoading] = useState(false);
	const [optionsOpen, setOptionsOpen] = useState(false);
	const [optionsLoading, setOptionsLoading] = useState(true);

	const getExternalGroupMembers = (e, option) => {
		e.preventDefault();
		setMembersLoading(true);
		setAnchorEl(e.currentTarget);
		axios.get(apiRoutes.getExternalGroupMembers(organizationId, option.externalGroupId), { headers: authHeader })
			.then((res) => {
				setIdpGroupMembers(res.data);
				setIdpGroupName(option.externalGroupName);
				setMembersLoading(false);
			})
			.catch(err => {
				console.log(err);
				setMembersLoading(false);
			});
	};

	const handleRenderTags = (tagValue, getTagProps) => {
		return tagValue.map((option, index) => {
			const { key, ...tagProps } = getTagProps({ index });
			return (
				<Chip
					icon={
						<CoAppExpandMoreIcon
							id={option.externalGroupId}
							clickedopen={anchorEl?.id === option.externalGroupId ? "true" : "false"}
							style={{ color: "#FFFFFF", backgroundColor: "#BDBDBD", borderRadius: "50%" }}
							onClick={(e) => getExternalGroupMembers(e, option)}
						/>
					}
					key={key}
					{...tagProps}
					label={
						membersLoading && anchorEl?.id === option.externalGroupId
							? <div style={{ display: "flex", justifyContent: "center" }}>
								<CircularProgress size="16px" sx={{ color: "#00000061", marginTop: "2px", marginRight: "5px" }} />
								Loading...
							</div> : option.externalGroupName
					}
				/>
			);
		});
	};

	const handleCheckboxRenderOptions = (props, option, { selected }) => {
		const { key, ...optionProps } = props;

		return (
			<li key={Math.random()} {...optionProps}>
				<Checkbox
					style={{ marginRight: 8 }}
					checked={selected}
					value={option}
				/>
				<div style={{ display: "block" }}>
					{option.externalGroupName}
				</div>
			</li>
		);
	};

	const handleSelectionChange = (event, value, reason) => {
		let temp = { ...mapped };
		temp[resourceName] = value;
		setMapped(temp);
		if (isSuperAdmin) {
			validateSuperAdminRoleIsMapped(value, "validateSuperAdminRoleIsMapped");
		}
		apiRef.current.resetRowHeights();
	};

	const handleOpen = () => {
		initializeGroupOptions()
			.then(() => {
				setOptionsOpen(true);
			})
	};

	const handleClose = () => {
		setOptionsOpen(false);
		setOptionsLoading(true);
		setExternalGroups([]);
	};

	return (
		<>
			<CoAppMultiSelect
				isFreeSolo={false}
				hasToolTip={true}
				disableClearable={false}
				limitTags={3}
				options={options}
				chipSize="small"
				value={mapped[resourceName] || []}
				onChange={handleSelectionChange}
				customRenderOptionLabel={(option) => option.externalGroupName}
				customRenderTags={handleRenderTags}
				customRenderCheckboxes={handleCheckboxRenderOptions}
				isOptionEqualToValue={(option, value) => option.externalGroupId === value.externalGroupId}
				loading={optionsLoading}
				onOpen={handleOpen}
				onClose={handleClose}
				open={optionsOpen}
			/>
			<ExternalGroupUsersPopover
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				members={idpGroupMembers}
				externalGroupName={idpGroupName}
				membersLoading={membersLoading}
			/>
		</>
	);
}