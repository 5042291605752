import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectSortPreferences, setSortPreferences } from "../redux/userSlice";

export default function useDatagridSorting(dataGridName) {
	const dispatch = useDispatch();
	const sortPreferences = useSelector(selectSortPreferences);
	const [sortModel, setSortModel] = useState([]);

	const handleSortModelChange = (newSortModel) => {
		const column = newSortModel[0]?.field || "";
		const lastSortedAs = newSortModel[0]?.sort || "";
		const tempSortPreferences = {
			...sortPreferences,
			[dataGridName]: {
				column: column,
				lastSortedAs: lastSortedAs
			}
		};
		dispatch(setSortPreferences(tempSortPreferences));
		setSortModel(newSortModel);
	};

	const handleInitializeSortModel = () => {
		if (sortPreferences[dataGridName].column !== "") {
			setSortModel([{ field: sortPreferences[dataGridName].column, sort: sortPreferences[dataGridName].lastSortedAs }]);
		}
	};

	return { sortModel, handleInitializeSortModel, handleSortModelChange };
}