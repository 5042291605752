import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	navState: [
		{ id: 1, name: "RULES", children: [], page: "", isClickable: false, isTitle: true },
		{ id: 2, name: "Build Rule", children: [], page: "/rules/new", isClickable: true },
		{
			id: 3,
			name: "Library",
			children: [
				{
					id: 4,
					name: "Trash",
					children: [],
					page: "/rules/trash",
					isClickable: true,
					keepOpen: true
				}
			],
			page: "/rules/library",
			isClickable: true,
			keepOpen: true
		},
		{ id: 5, name: "Divider", children: [], page: "", isClickable: false },
		{ id: 6, name: "APP SETTINGS", children: [], page: "", isClickable: false, isTitle: true },
		{ id: 7, name: "Organization", children: [], page: "/settings/organization", isClickable: true },
		{ id: 8, name: "Users", children: [], page: "/settings/users", isClickable: true },
		{ id: 9, name: "Groups", children: [], page: "/settings/groups", isClickable: true },
		{ id: 10, name: "Roles", children: [], page: "/settings/roles", isClickable: true },
		{ id: 11, name: "Workstations", children: [], page: "/settings/workstations", isClickable: true },
		{ id: 12, name: "Plugins", children: [], page: "/settings/plugins", isClickable: true }
	],
	groupChildren: [
		{ id: 13, name: "Rules", page: "", children: [] },
		{ id: 14, name: "Users", page: "", children: [] },
		{ id: 15, name: "Plugins", page: "", children: [] },
		{ id: 16, name: "Add/Remove IdP Groups", page: "", children: [] }
	],
	roleChildren: [
		{ id: 17, name: "Users", page: "", children: [] },
		{ id: 18, name: "Permissions", page: "", children: [] },
		{ id: 19, name: "Add/Remove IdP Groups", page: "", children: [] }
	],
	pluginChildren: [
		{ id: 20, name: "Groups", page: "", children: [] }
	],
	organizationChildren: [
		{ id: 21, name: "SSO + SCIM Configuration", page: "/settings/organization/sso-scim", isClickable: true, children: [] },
	],
	expandedItems: ["3"],
	selectedItem: {
		id: 3,
		name: "Library",
		children: [
			{
				id: 4,
				name: "Trash",
				children: [],
				page: "/rules/trash",
				isClickable: true,
				keepOpen: true
			}
		],
		page: "/rules/library",
		isClickable: true,
		keepOpen: true
	},
};

export const navigationSlice = createSlice({
	name: "navigation",
	initialState,
	reducers: {
		setExpandedItems: (state, action) => { state.expandedItems = action.payload; },
		resetNavigationState: (state) => state = initialState,
		setNavState: (state, action) => { state.navState = action.payload; },
		setSelectedItem: (state, action) => { state.selectedItem = action.payload; },
	}
});

export const selectExpandedItems = (state) => state.root.navigation.expandedItems;
export const selectGroupChildren = (state) => state.root.navigation.groupChildren;
export const selectInitialState = () => initialState;
export const selectNavState = (state) => state.root.navigation.navState;
export const selectPluginChildren = (state) => state.root.navigation.pluginChildren;
export const selectOrganizationChildren = (state) => state.root.navigation.organizationChildren;
export const selectRoleChildren = (state) => state.root.navigation.roleChildren;
export const selectSelectedItem = (state) => state.root.navigation.selectedItem;

export const {
	resetNavigationState,
	setExpandedItems,
	setNavState,
	setPreviousExpandedItems,
	setPreviousNavState,
	setPreviousSelectedItem,
	setSelectedItem,
	setSelectedUser
} = navigationSlice.actions;

export default navigationSlice.reducer;