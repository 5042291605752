import { useState } from "react";
import { ContentCopy } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { CoAppCopyBlockContainer, CoAppCopyBlockTypography, CoAppLightTooltip } from "../../styled/global.styled";

export default function CoAppCopyBlock(props) {
	const { labelText, textToBeCopied } = props;
	const [copyText, setCopyText] = useState("Click to copy");
	const handleCopyText = () => {
		try {
			navigator.clipboard.writeText(textToBeCopied);
			setCopyText("Copied!");
			setTimeout(() => {
				setCopyText("Click to copy");
			}, 1500);
		} catch (e) {
			setCopyText("Failed to copy!");
			setTimeout(() => {
				setCopyText("Click to copy");
			}, 1500);
		}
	};

	return (
		<CoAppCopyBlockContainer>
			<Stack direction="row">
				<Stack>
					<Typography variant="body2" color="text.secondary">{labelText}</Typography>
					<Stack direction="row">
						<CoAppCopyBlockTypography variant="body1" color="text.primary">{textToBeCopied}</CoAppCopyBlockTypography>
						<CoAppLightTooltip title={copyText} placement="right">
							<ContentCopy onClick={handleCopyText} sx={{ alignSelf: "end" }} />
						</CoAppLightTooltip>
					</Stack>
				</Stack>
			</Stack>
		</CoAppCopyBlockContainer >
	);
}