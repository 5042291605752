import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, Delete, Edit } from "@mui/icons-material";
import { Alert, Box, Dialog, Divider, Stack, Typography } from "@mui/material";

import messages from "../../../constants/messages";
import { selectEditedVersionOfRule, selectTriggers, setTriggers } from "../../../redux/ruleWizardSlice";
import { CoAppTextButton } from "../../global/styled/global.styled";
import { RuleSidebarRow, RuleSidebarRowActions, RuleTriggerSidebarContainer
} from "../styled/rule-sidebars.styled";

import TriggerDialog from "./trigger-dialog";

export default function RuleTriggerSidebar(props) {
	const dispatch = useDispatch();

	const editedVersionOfRule = useSelector(selectEditedVersionOfRule);
	const triggers = useSelector(selectTriggers);

	const handleSaveChanges = props.handleSaveChanges;

	/**
	 * Location previously selected
	 * Triggers should be associated with a location
	 */
	const location = props.location;

	/**
	 * Trigger Object Fields, based on user input
	 */
	const [triggerField, setTriggerField] = useState({});
	const [triggerName, setTriggerName] = useState("");
	const instanceType = "currentInstance";
	const [isEditing, setIsEditing] = useState(false);

	/**
	 * Trigger field rule error state.
	 */
	const [triggerFieldValueError, setTriggerFieldValueError] = useState("");
	const [triggerNameValueError, setTriggerNameValueError] = useState("");
	const [formHasErrors, setFormHasErrors] = useState(false);
	const [noLocationSelectedError, setNoLocationSelectedError] = useState("");

	/**
	 * Triggers data manipulation
	 */
	const [editTriggerIndex, setEditTriggerIndex] = useState("");

	/**
	 * New/Edit Trigger - Dialog Handling
	 */
	const [open, setOpen] = useState(false);

	/**
	 * Validate trigger dialog field before allowing user to save to redux.
	 * @returns boolean
	 */
	const validateFields = () => {
		let hasErrors = false;
		if (Object.keys(triggerField).length === 0 || triggerField === "") {
			setTriggerFieldValueError(messages.FIELD_IS_REQUIRED_ERROR_MSG);
			hasErrors = true;
		} else {
			setTriggerFieldValueError("");
		}

		if (triggerName === "") {
			setTriggerNameValueError(messages.FIELD_IS_REQUIRED_ERROR_MSG);
			hasErrors = true;
		} else {
			setTriggerNameValueError("");
		}

		if (hasErrors) {
			setFormHasErrors(true);
			return false;
		} else {
			return true;
		}
	};

	const clearFields = () => {
		setTriggerField({});
		setTriggerName("");
		setTriggerFieldValueError("");
		setTriggerNameValueError("");
	};

	const handleClickOpen = () => {
		if (Object.keys(location).length === 0) {
			setNoLocationSelectedError(messages.TRIGGER_NO_LOCATION_SELECTED_ERROR_MSG);
			return;
		}
		clearFields();
		setOpen(true);
		setIsEditing(false);
	};

	const handleCancel = () => {
		clearFields();
		setOpen(false);
	};

	/**
	 * Edit Dialog handling
	 */
	const handleClickOpenEditDialog = (trigger, index) => {
		props.handleTriggerFieldLoad(trigger.location);
		setTriggerField(JSON.stringify(trigger.triggerField));
		setTriggerName(trigger.triggerName);
		setEditTriggerIndex(index);
		setIsEditing(true);
		setOpen(true);
	};

	const handleEditSave = async () => {
		if (!validateFields()) {
			return;
		} else {
			let updatedTrigger = {
				id: Math.random(),
				triggerField: JSON.parse(triggerField),
				triggerName: triggerName,
				instanceType: instanceType,
				location: triggers[editTriggerIndex].location
			};

			let nt = [...triggers];
			nt.splice(editTriggerIndex, 1, updatedTrigger);
			setEditTriggerIndex("");
			setTriggers(nt);
			dispatch(setTriggers(nt));
			setIsEditing(false);
			setOpen(false);
			handleSaveChanges(nt);

			// TODO: need this?
			if (props.editedId) {
				props.handleStepDataChange(true);
			}
		}
	};

	const handleDeleteTrigger = (index) => {
		let triggerIndex = index ? index : editTriggerIndex;
		let nt = [...triggers];
		nt.splice(triggerIndex, 1);
		setEditTriggerIndex("");
		dispatch(setTriggers(nt));
		setIsEditing(false);
		setOpen(false);
		handleSaveChanges(nt);
	};

	/**
	 * Add new trigger to array of triggers managed by redux
	 */
	const handleSave = () => {
		// if (!validateFields()) {
		// 	return;
		// } else {
		let newTrigger = {
			id: Math.random(),
			triggerField: JSON.parse(triggerField),
			triggerName: triggerName,
			instanceType: instanceType,
			location: location
		};

		dispatch(setTriggers([...triggers, newTrigger]));
		setOpen(false);
		handleSaveChanges([...triggers, newTrigger]);

		// TODO: need this?
		if (props.editedId) {
			props.handleStepDataChange(true);
		}
		//}
	};

	/**
	 * Individual Field handling in the New and Edit dialogs
	 */
	const handleTriggerNameInput = (e) => {
		setTriggerName(e.target.value);
		if (e.target.value.length === 0) {
			setFormHasErrors(true);
			setTriggerNameValueError(messages.FIELD_IS_REQUIRED_ERROR_MSG);
		} else {
			setFormHasErrors(false);
			setTriggerNameValueError("");
		}
	};

	/**
	 * Field has changed. 
	 * If field has otherInstanceEnabled = true, 
	 * then display instance type options
	 */
	const handleFieldDropdownSelectionChange = (e) => {
		setTriggerField(e.target.value);
		setTriggerFieldValueError("");
		setFormHasErrors(false);
	};

	useEffect(() => {
		if (props.editedId && Object.keys(editedVersionOfRule).length > 0) {
			if (triggers.length !== editedVersionOfRule.ruletriggers.length) {
				props.handleStepDataChange(true);
			}
		}
	}, [location, triggers]);

	return (
		<div>
			<RuleTriggerSidebarContainer>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Typography sx={{ padding: "5px"}} variant="body1">Triggers</Typography>
					<CoAppTextButton onClick={handleClickOpen}><Add/></CoAppTextButton>
				</Box>
				<Divider sx={{ width: "100%" }} />

				<Stack alignItems="flexStart" direction="column" spacing="0.5" sx={{ overflow: "auto" }}>
					{
						triggers.map((trigger, index) =>
							<div key={trigger.id}>
								<RuleSidebarRow>
									<div>
										<Typography sx={{ fontWeight: "600" }} variant="body2">{trigger.triggerName}</Typography>
										<Typography variant="body2">Location: {trigger.location.name}</Typography>
										<Typography variant="body2">Description: {trigger.location.description}</Typography>
										<Typography variant="body2">Field Name: {trigger.triggerField.name}</Typography>
										<Typography variant="body2">Field Type: {trigger.triggerField.fieldType}</Typography>
									</div>
									<RuleSidebarRowActions alignItems="flexStart" direction="row">
										<Edit onClick={() => handleClickOpenEditDialog(trigger, index)} sx={{ fontSize: "medium", color: "#1D38BD", marginRight: "10px" }} />
										<Delete onClick={() => handleDeleteTrigger(index)} sx={{ fontSize: "medium", color: "#1D38BD" }} />
									</RuleSidebarRowActions>
								</RuleSidebarRow>
								<Divider sx={{ width: "100%" }} />
							</div>
						)
					}
				</Stack>
			</RuleTriggerSidebarContainer>

			<TriggerDialog
				openDialogFlag={open}
				openAsEditFlag={isEditing}
				saveHandler={handleSave}
				cancelHandler={handleCancel}
				saveEditHandler={handleEditSave}
				deleteTriggerHandler={handleDeleteTrigger}
				nameOfTrigger={triggerName}
				nameOfTriggerField={triggerField}
				allTriggerFields={props.triggerFields}
				cancelDialogHandler={handleCancel}
				triggerNameInputHandler={handleTriggerNameInput}
				fieldDropdownSelectionChangeHandler={handleFieldDropdownSelectionChange}
				triggerNameValueError={triggerNameValueError}
				triggerFieldValueError={triggerFieldValueError}
				formHasErrors={formHasErrors}
			/>

			<Dialog onClose={() => setNoLocationSelectedError("")} open={noLocationSelectedError !== ""}>
				<Alert severity="error">{noLocationSelectedError}</Alert>
			</Dialog>
		</div>
	);
}