import { Fragment } from "react";
import { Stack, Step, StepLabel, Stepper } from "@mui/material";

import { ScimWizardStepIcon } from "../styled/scim-wizard.styled";

export default function ScimWizardStepper(props) {
	const activeStep = props.activeStep;
	const steps = props.steps;
	const stepsCompleted = props.stepsCompleted;

	return (
		<Fragment>
			<Stack sx={{ width: "100%", padding: "25px" }} direction="row" >
				<Stepper alternativeLabel nonLinear activeStep={activeStep} sx={{ width: { xxl: "50%", xl: "60%", lg: "60%", sm: "75%" } }}>
					{steps.map(step => (
						<Step completed={stepsCompleted[step.num - 1]} key={step.num}>
							<StepLabel StepIconComponent={ScimWizardStepIcon}>
								{step.desc}
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</Stack>
		</Fragment>
	);
}
