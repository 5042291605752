import { getSessionToken } from "@descope/react-sdk";

function useAuthHeader() {
	const sessionToken = getSessionToken();
	if (sessionToken) {
		return { "Authorization": "Bearer " + sessionToken, "content-type": "application/json" };
	} else {
		return {};
	}
}

export default useAuthHeader;