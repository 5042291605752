import { useSelector } from "react-redux";

import { selectPermissions } from "../redux/userSlice";

export default function useAuthorization(requiredPermissions, isInclusive = true) {
	const permissions = useSelector(selectPermissions);
	if (requiredPermissions && permissions) {

		if (!permissions.includes("ACCESS_MP")) return false;

		if (isInclusive) {
			return requiredPermissions.every(permission => permissions.includes(permission));
		} else {
			if (permissions.includes("ACCESS_MP")) {
				let tempPermissions = [...permissions].filter(permission => permission !== "ACCESS_MP");
				return requiredPermissions.some(permission => tempPermissions.includes(permission));
			} else {
				return false;
			}
		}
	} else {
		return false;
	}
}