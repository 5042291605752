import { useState } from "react";
import { CheckBox, IndeterminateCheckBox } from "@mui/icons-material";
import {
	Autocomplete, Checkbox, Chip,
	DialogActions, DialogContent, DialogContentText,
	DialogTitle, Stack
} from "@mui/material";

import messages from "../../../../constants/messages";
import {
	CoAppActionButton, CoAppCloseIcon, CoAppDialog,
	CoAppDialogTooltipContainer, CoAppDialogTooltipText, CoAppLightTooltip,
	CoAppTextButton
} from "../../styled/global.styled";
import CoAppMultiSelect from "../inputs/coapp-multiselect";
import CoAppTextField from "../inputs/coapp-textfield";

export default function CoAppBulkApplyModal(props) {
	const {
		dialogOpen, dialogTitle, cancelClickHandler,
		confirmClickhandler, availableItems, items,
		selectedItemChangeHandler, resource, targetResource,
		selectedRules, totalRules, itemsRemoved,
		calculateItemsRemovedFromAll, calculateItemsToAddToAll, calculateUnchangedItems
	} = props;

	const model = resource.toLowerCase().includes("tag") ? "tags" : "groups";
	const [isConfirmationStep, setIsConfirmationStep] = useState(false);

	const handleClickNext = () => {
		setIsConfirmationStep(true);
	};

	const handleClickBack = () => {
		setIsConfirmationStep(false);
	};

	const getChipBackground = (tagType) => {
		switch (tagType) {
			case "added":
				return "#00000014";
			case "removed":
				return "#DE37304D";
			case "unchanged":
				return "repeating-linear-gradient(45deg, #FFFFFF 0px, #FFFFFF 5px, #EDEFEE 5px, #EDEFEE 15px, #FFFFFF 15px, #FFFFFF 15px, #EDEFEE 15px, #EDEFEE 20px)";
			case "mixedState":
				return "repeating-linear-gradient(45deg, #FFFFFF 0px, #FFFFFF 5px, #EDEFEE 5px, #EDEFEE 15px, #FFFFFF 15px, #FFFFFF 15px, #EDEFEE 15px, #EDEFEE 20px)";
			default:
				return "#00000014";
		}
	};

	const handleRenderTagsForReadOnly = (tagValue, getTagProps, tagType) => {
		return tagValue.map((option, index) => {
			const { key, ...tagProps } = getTagProps({ index });
			const tempTagProps = { ...tagProps };
			delete tempTagProps.onDelete; // remove the on delete listener to enforce a custom read only mode
			const { count, appliedToAll } = calculateAppliedToCount(tagType, option);
			if (tagType === "unchanged") {
				return (
					<CoAppLightTooltip
						key={key}
						title={
							<CoAppDialogTooltipContainer>
								<CoAppDialogTooltipText name="true">{`Applied to ${appliedToAll ? `all ${count}` : `${count}`} of the selected ${targetResource}`}</CoAppDialogTooltipText>
							</CoAppDialogTooltipContainer>
						}
					>
						<Chip {...tempTagProps} label={option} sx={{ background: getChipBackground(tagType) }} />
					</CoAppLightTooltip>
				);
			} else {
				return (
					<Chip key={key} {...tempTagProps} label={option} sx={{ background: getChipBackground(tagType) }} />
				);
			}
		});
	};

	const getTagType = (option) => {
		const couldBeMixedState = selectedRules.some(ruleId => {
			let foundRule = totalRules.find(rule => rule.id === ruleId);
			return foundRule[model].map(item => item.name).includes(option);
		});
		let isAppliedToAll = false;
		if (couldBeMixedState) {
			isAppliedToAll = selectedRules.every(ruleId => {
				let foundRule = totalRules.find(rule => rule.id === ruleId);
				return foundRule[model].map(item => item.name).includes(option) || itemsRemoved.map(item => item.name).includes(option);
			});
		}

		let isCurrentlyAppliedToNone = false;
		if (!couldBeMixedState) {
			isCurrentlyAppliedToNone = selectedRules.every(ruleId => {
				let foundRule = totalRules.find(rule => rule.id === ruleId);
				return !foundRule[model].map(item => item.name).includes(option);
			});
		}

		if (isAppliedToAll || isCurrentlyAppliedToNone) {
			return "added";
		} else if (couldBeMixedState) {
			return "mixedState";
		} else {
			return "removed";
		}
	};

	const calculateAppliedToCount = (tagType, option) => {
		let count = 0;
		let appliedToAll = false;
		if (tagType === "mixedState" || tagType === "unchanged") {
			count = selectedRules.filter(ruleId => {
				let foundRule = totalRules.find(rule => rule.id === ruleId);
				return foundRule[model].map(item => item.name).includes(option);
			}).length;
		} else {
			count = selectedRules.length;
			appliedToAll = true;
		}
		return { count, appliedToAll };
	};

	const handleRenderTagsWhileEditing = (tagValue, getTagProps) => {
		return tagValue.map((option, index) => {
			const { key, ...tagProps } = getTagProps({ index });
			const tagType = getTagType(option);
			const { count, appliedToAll } = calculateAppliedToCount(tagType, option);
			return (
				<CoAppLightTooltip
					key={key}
					title={
						<CoAppDialogTooltipContainer>
							<CoAppDialogTooltipText name="true">{`Applied to ${appliedToAll ? `all ${count}` : `${count}`} of the selected ${targetResource}`}</CoAppDialogTooltipText>
						</CoAppDialogTooltipContainer>
					}
				>
					<Chip {...tagProps} label={option} sx={{ background: getChipBackground(tagType) }} />
				</CoAppLightTooltip>
			);
		});
	};

	const handleRenderCheckboxes = (props, option, { selected }) => {
		const { key, ...optionProps } = props;
		const tagType = getTagType(option);
		return (
			<li key={Math.random()} {...optionProps} style={{ cursor: isConfirmationStep ? "default" : "pointer", pointerEvents: isConfirmationStep ? "none" : "auto" }}>
				<Checkbox
					style={{ marginRight: 8 }}
					checked={selected}
					value={option}
					checkedIcon={tagType === "mixedState" ? <IndeterminateCheckBox /> : <CheckBox />}
				/>
				<div style={{ display: "block" }}>
					{option}
				</div>
			</li >
		);
	};

	const resetModal = () => {
		setIsConfirmationStep(false);
		cancelClickHandler();
	};

	const handleConfirmClick = () => {
		confirmClickhandler();
		setIsConfirmationStep(false);
	};

	return (
		<CoAppDialog open={dialogOpen} onClose={resetModal} PaperProps={{ sx: { borderRadius: "8px", padding: "8px", width: "500px" } }}>
			<DialogTitle variant="h6">{isConfirmationStep ? "Save changes?" : dialogTitle}</DialogTitle>
			<CoAppCloseIcon onClick={resetModal} top={8} left={468} />
			<DialogContent>
				{
					isConfirmationStep ?
						<Stack>
							<Autocomplete
								freeSolo
								multiple
								disableClearable
								size="medium"
								value={calculateItemsToAddToAll().map((item) => item.name)}
								options={availableItems.map((item) => item.name)}
								getOptionLabel={(option) => option}
								renderTags={(tagValue, getTagProps) => handleRenderTagsForReadOnly(tagValue, getTagProps, "added")}
								renderInput={(params) => (
									<CoAppTextField
										{...params}
										InputLabelProps={{
											style: { color: "#1D38BD" },
										}}
										label={`${resource} added to all selected ${targetResource} (${selectedRules.length})`}
									/>
								)}
								renderOption={handleRenderCheckboxes}
							/>
							<Autocomplete
								freeSolo
								disableClearable
								multiple
								value={calculateItemsRemovedFromAll().map((item) => item.name)}
								options={availableItems.map((item) => item.name)}
								renderTags={(tagValue, getTagProps) => handleRenderTagsForReadOnly(tagValue, getTagProps, "removed")}
								getOptionLabel={(option) => option}
								renderInput={(params) => (
									<CoAppTextField
										{...params}
										InputLabelProps={{
											style: { color: "#1D38BD" },
										}}
										label={`${resource} removed from all selected ${targetResource} (${selectedRules.length})`}
									/>
								)}
								renderOption={handleRenderCheckboxes}
							/>
							<Autocomplete
								freeSolo
								multiple
								disableClearable
								value={calculateUnchangedItems().map((item) => item.name)}
								options={availableItems.map((item) => item.name)}
								renderTags={(tagValue, getTagProps) => handleRenderTagsForReadOnly(tagValue, getTagProps, "unchanged")}
								getOptionLabel={(option) => option}
								renderInput={(params) => (
									<CoAppTextField
										{...params}
										InputLabelProps={{
											style: { color: "#1D38BD" },
										}}
										label={`${resource} left unchanged`}
									/>
								)}
								renderOption={handleRenderCheckboxes}
							/>
						</Stack>
						:
						<>
							<DialogContentText
								variant="body2"
								color="text.primary"
							>
								{messages.BULK_APPLY_MODAL_MESSAGE(resource.replaceAll("(", "").replaceAll(")", ""), targetResource)}
							</DialogContentText>
							<CoAppMultiSelect
								disabled={false}
								isFreeSolo={false}
								label={resource}
								handleRenderTags={(tagValue, getTagProps) => handleRenderTagsWhileEditing(tagValue, getTagProps)}
								options={availableItems.map((item) => item.name)}
								value={items.map((item) => item.name)}
								onChange={(e, value, reason) => selectedItemChangeHandler(value)}
								hasToolTip={true}
								customRenderTags={handleRenderTagsWhileEditing}
								customRenderCheckboxes={handleRenderCheckboxes}
							/>
						</>
				}
			</DialogContent>
			<DialogActions sx={{ marginRight: "15px", marginBottom: "10px" }}>
				<CoAppTextButton onClick={isConfirmationStep ? handleClickBack : resetModal}>{isConfirmationStep ? "Back" : "Cancel"}</CoAppTextButton>
				<CoAppActionButton onClick={isConfirmationStep ? handleConfirmClick : handleClickNext}>{isConfirmationStep ? "Save" : "Next"}</CoAppActionButton>
			</DialogActions>
		</CoAppDialog >
	);
}