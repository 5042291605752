import { CssBaseline } from "@mui/material";

import AlertSnackbar from "./components/global/components/alert-snackbar";

export default function App() {
	return (
		<div>
			<CssBaseline />
			<AlertSnackbar />
		</div>
	);
}