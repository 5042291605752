import { CoAppTextButton, CoAppTextDestructiveButton } from "../../styled/global.styled";

export default function CoAppIconTextButton({ icon, onClick, text, isDestructive = false }) {
	if (isDestructive) {
		return (
			<CoAppTextDestructiveButton onClick={onClick}>
				{icon}
				{text}
			</CoAppTextDestructiveButton>
		);
	} else {
		return (
			<CoAppTextButton onClick={onClick} ismodal="false">
				{icon}
				{text}
			</CoAppTextButton>
		);
	}
}