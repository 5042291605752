import { CoAppActionButton } from "../../styled/global.styled";

export default function CoAppIconActionButton({ icon = null, onClick, text }) {
	if (icon) {
		return (
			<CoAppActionButton onClick={onClick}>
				{icon}
				{text}
			</CoAppActionButton>
		);
	} else {
		return (
			<CoAppActionButton onClick={onClick}>
				{text}
			</CoAppActionButton>
		);
	}
}