import { Tooltip } from "@mui/material";

import { FormulaItem } from "../styled/formula-card.styled";

export const FormulaItemWrapper = ({ borderColor, item, triggerName, onDelete }) => (
	<Tooltip followCursor title={item.rulefield ?
		<div style={{ padding: "0px", display: "inline-block" }}>
			<p>Location: {item.rulelocation.name}</p>
			<p>Field Name: {item.rulefield.name}</p>
			<p>Field Type: {item.rulefield.fieldType}</p>
			{item.which ? <p>Which: {item.which}</p> : ""}
			{item.when ? <p>When: {item.when}</p> : ""}
		</div>
		:
		""
	}
	>
		<FormulaItem borderColor={borderColor}> {triggerName}
			<div style={{
				position: "absolute",
				right: "10px",
				top: "50%",
				transform: "translateY(-50%)",
				visibility: "hidden",
				transition: "visibility 0.2s ease-in-out" }}>
			</div>
		</FormulaItem>
	</Tooltip>
);