import { Stack, styled } from "@mui/material";

export const OrganizationManagementCard = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "center",
	color: theme.palette.text.secondary,
	boxShadow: "0 1px 3px grey",
	borderRadius: "5px",
	position: "relative",
	width: "30%",
	margin: "10px auto",
	display: "flex"
}));

export const KillSwitchDialogContent = styled("div")(() => ({
	display: "flex",
	justifyContent: "space-evenly"
}));

export const OrganizationManagementItemStatusText = styled("div")(() => ({
	color: "#245055",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));

export const OrganizationManagementItemTitle = styled("p")(() => ({
	fontSize: "1rem",
	marginLeft: "10px",
	fontWeight: "600"
}));

export const OrganizationManagementStack = styled(Stack)(({ theme }) => ({
	display: "flex",
	padding: "30px"
}));