import { Delete, Restore } from "@mui/icons-material";
import { IconButton, Typography, useTheme } from "@mui/material";

import CoAppBulkActionGroupIcon from "../global/components/icons/coapp-bulk-action-group-icon";
import CoAppBulkActionTagIcon from "../global/components/icons/coapp-bulk-action-tag-icon";
import CoAppBulkActivateIcon from "../global/components/icons/coapp-bulk-activate-icon";
import CoAppBulkDeactivateIcon from "../global/components/icons/coapp-bulk-deactivate-icon";
import { CoAppDataGridAllSelectedContainer, CoAppLightTooltip, CoAppTextButton } from "../global/styled/global.styled";

export default function RuleDataGridSelectionRow(props) {
	const {
		totalRules, selectedRules, onDeactivateClickHandler,
		onActivateClickHandler, onTagClickHandler, onGroupClickHandler,
		onDeleteClickHandler, onClearSelectionClickHandler, onRestoreClickHandler,
		shouldShow = false, isTrash = false, activateIsDisabled, deactivateIsDisabled
	} = props;
	const theme = useTheme();

	const getDeactivateTooltip = () => {
		const objectifiedSelectedRules = selectedRules.map(rule => {
			const foundRule = totalRules.find(totalRule => totalRule.id === rule);
			return foundRule;
		});
		const toBeDeactivatedCount = objectifiedSelectedRules.filter(rule => rule.active).length;
		return toBeDeactivatedCount === 0 ? "No active rules selected" : `Deactivate selected active rules (${toBeDeactivatedCount})`;
	};

	const getActivateTooltip = () => {
		const objectifiedSelectedRules = selectedRules.map(rule => {
			const foundRule = totalRules.find(totalRule => totalRule.id === rule);
			return foundRule;
		});
		const toBeActivatedCount = objectifiedSelectedRules.filter(rule => !rule.active).length;
		return toBeActivatedCount === 0 ? "No inactive rules selected" : `Activate selected inactive rules (${toBeActivatedCount})`;
	};

	const getGroupTooltip = () => {
		return `Add/remove groups for selected rules (${selectedRules.length})`;
	};

	const getTagTooltip = () => {
		return `Add/remove tags for selected rules (${selectedRules.length})`;
	};

	const getDeleteTooltip = () => {
		return `Soft delete selected rules (${selectedRules.length})`;
	};

	const getRestoreTooltip = () => {
		return `Restore selected rules (${selectedRules.length})`;
	};

	const getPermanentlyDeleteTooltip = () => {
		return `Permanently delete selected rules (${selectedRules.length})`;
	};

	return (
		<>
			{
				shouldShow
					?
					(<CoAppDataGridAllSelectedContainer>
						<Typography variant="buttonSmall" color="text.coappPrimary" >
							{totalRules.length === selectedRules.length ? `All ${selectedRules.length} ` : selectedRules.length} {selectedRules.length > 1 ? "rules are" : "rule is"} selected.
						</Typography>
						<CoAppTextButton isrow="true" onClick={onClearSelectionClickHandler}>
							Clear selection
						</CoAppTextButton>
						{
							isTrash ?
								<>
									<CoAppLightTooltip title={getRestoreTooltip()}>
										<IconButton onClick={onRestoreClickHandler}>
											<Restore sx={{ color: theme.palette.icon.grey }} />
										</IconButton>
									</CoAppLightTooltip>
									<CoAppLightTooltip title={getPermanentlyDeleteTooltip()}>
										<IconButton onClick={onDeleteClickHandler} variant="destructive" color="red">
											<Delete sx={{ color: theme.palette.error.main, fontSize: "24px" }} />
										</IconButton>
									</CoAppLightTooltip>
								</>
								:
								<>
									<CoAppLightTooltip title={getActivateTooltip()}>
										<span>
											<CoAppBulkActivateIcon onClick={onActivateClickHandler} disabled={activateIsDisabled} />
										</span>
									</CoAppLightTooltip>
									<CoAppLightTooltip title={getDeactivateTooltip()}>
										<span>
											<CoAppBulkDeactivateIcon onClick={onDeactivateClickHandler} disabled={deactivateIsDisabled} />
										</span>
									</CoAppLightTooltip>
									<CoAppLightTooltip title={getGroupTooltip()}>
										<CoAppBulkActionGroupIcon onClick={onGroupClickHandler} />
									</CoAppLightTooltip>
									<CoAppLightTooltip title={getTagTooltip()}>
										<CoAppBulkActionTagIcon onClick={onTagClickHandler} />
									</CoAppLightTooltip>
									<CoAppLightTooltip title={getDeleteTooltip()}>
										<IconButton onClick={onDeleteClickHandler} variant="destructive" color="red">
											<Delete sx={{ color: theme.palette.error.main, fontSize: "24px" }} />
										</IconButton>
									</CoAppLightTooltip>
								</>
						}
					</CoAppDataGridAllSelectedContainer>)
					:
					null
			}
		</>
	);
}