import { useState } from "react";
import { Stack, Typography } from "@mui/material";

import CoAppCopyBlock from "../../../../global/components/inputs/coapp-copy-block";
import ScimWizardEmbeddedVideo from "../scim-wizard-embedded-video";
import ssoScimVideoLinks from "../sso-scim-video-links";
import { ScimWizardStepContainer } from "../styled/scim-wizard.styled";

const descopeScimUrl = "https://api.descope.com/scim/v2/";

export default function Step4(props) {
	const { secretToken, identityProviderName } = props;

	const [showEnterScimDetailsVideo, setShowEnterScimDetailsVideo] = useState(false);
	const [showEnterScimDetailsThumbnail, setShowEnterScimDetailsThumbnail] = useState(false);
	const [showTestConnectionVideo, setShowTestConnectionVideo] = useState(false);
	const [showTestConnectionThumbnail, setShowTestConnectionThumbnail] = useState(false);
	const [showProvisionGroupWithMembersVideo, setShowProvisionGroupWithMembersVideo] = useState(false);
	const [showProvisionGroupWithMembersThumbnail, setShowProvisionGroupWithMembersThumbnail] = useState(false);
	const [startScimProvisioningVideo, setStartScimProvisioningVideo] = useState(false);
	const [startScimProvisioningThumbnail, setStartScimProvisioningThumbnail] = useState(false);

	return (
		<ScimWizardStepContainer spacing={4}>
			<Typography variant="h3_5" color="text.primary">Configure SCIM</Typography>
			<Stack direction="row" spacing={1}>
				<Typography variant="body1" color="text.primary">{`1. Enter the following authentication credentials into ${identityProviderName}.`}</Typography>
				<ScimWizardEmbeddedVideo
					showVideo={showEnterScimDetailsVideo}
					setShowVideo={setShowEnterScimDetailsVideo}
					showThumbnail={showEnterScimDetailsThumbnail}
					setShowThumbnail={setShowEnterScimDetailsThumbnail}
					iframeTitle="Enter SCIM Credentials Video"
					iframeSrc={ssoScimVideoLinks.enterScimCredentialsVideo}
					thumbnailSrc={ssoScimVideoLinks.enterScimCredentialsThumbnail}
					thumbnailAltText="Enter SCIM Credentials Video Thumbnail"
				/>
			</Stack>
			<CoAppCopyBlock
				labelText="Tenant URL"
				textToBeCopied={descopeScimUrl}
			/>
			<CoAppCopyBlock
				labelText="Secret Token"
				textToBeCopied={secretToken}
			/>
			<Stack direction="row" spacing={1}>
				<Typography variant="body1" color="text.primary">2. Test the connection to CoApp.</Typography>
				<ScimWizardEmbeddedVideo
					showVideo={showTestConnectionVideo}
					setShowVideo={setShowTestConnectionVideo}
					showThumbnail={showTestConnectionThumbnail}
					setShowThumbnail={setShowTestConnectionThumbnail}
					iframeTitle="Test Connection Video"
					iframeSrc={ssoScimVideoLinks.testScimConnectionVideo}
					thumbnailSrc={ssoScimVideoLinks.testScimConnectionThumbnail}
					thumbnailAltText="Test Connection Video Thumbnail"
				/>
			</Stack>
			<Stack direction="row" spacing={1}>
				<Typography variant="body1" color="text.primary">3. Provision your users and groups on-demand.</Typography>
				<ScimWizardEmbeddedVideo
					showVideo={showProvisionGroupWithMembersVideo}
					setShowVideo={setShowProvisionGroupWithMembersVideo}
					showThumbnail={showProvisionGroupWithMembersThumbnail}
					setShowThumbnail={setShowProvisionGroupWithMembersThumbnail}
					iframeTitle="Provision Groups with Members Video"
					iframeSrc={ssoScimVideoLinks.provisionGroupWithMembersVideo}
					thumbnailSrc={ssoScimVideoLinks.provisionGroupWithMembersThumbnail}
					thumbnailAltText="Provision Groups with Members Video Thumbnail"
				/>
			</Stack>
			<Stack direction="row" spacing={1}>
				<Typography variant="body1" color="text.primary">4. Start SCIM provisioning.</Typography>
				<ScimWizardEmbeddedVideo
					showVideo={startScimProvisioningVideo}
					setShowVideo={setStartScimProvisioningVideo}
					showThumbnail={startScimProvisioningThumbnail}
					setShowThumbnail={setStartScimProvisioningThumbnail}
					iframeTitle="Start SCIM Provisioning Video"
					iframeSrc={ssoScimVideoLinks.startScimProvisioningVideo}
					thumbnailSrc={ssoScimVideoLinks.startScimProvisioningThumbnail}
					thumbnailAltText="Start SCIM Provisioning Video Thumbnail"
				/>
			</Stack>
		</ScimWizardStepContainer>
	);
}