import { forwardRef } from "react";
import { IconButton } from "@mui/material";

const CoAppBulkActionTagIcon = forwardRef(function CoAppBulkActionTagIcon(props, ref) {
	const { onClick } = props;
	return (
		<IconButton onClick={onClick} ref={ref} {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<path d="M20.9999 15H19.1999H17.3999V16.8H19.1999H20.9999H22.7999V15H20.9999Z" fill="#1D38BD" />
				<path d="M20.9999 8.39998V6.59998H19.1999V8.39998H17.3999V10.2H19.1999V12H20.9999V10.2H22.7999V8.39998H20.9999Z" fill="#1D38BD" />
				<path d="M13.32 6.72C13.0168 6.28286 12.5116 6 11.9474 6L2.68421 6.00857C1.75789 6.00857 1 6.77143 1 7.71429V16.2857C1 17.2286 1.75789 17.9914 2.68421 17.9914L11.9474 18C12.5116 18 13.0168 17.7171 13.32 17.28L17 12L13.32 6.72Z" fill="#1D38BD" />
			</svg>
		</IconButton>
	);
});

export default CoAppBulkActionTagIcon;