import { styled, Switch } from "@mui/material";

export const WorkstationSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: theme.palette.error.main,
		"&:hover": {
			backgroundColor: theme.palette.error._states.hover,
		},
	},
	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
		backgroundColor: theme.palette.error.main
	},
}));