import { CoAppDataGrid } from "../../../global/styled/global.styled";

export default function ScimWizardDataGrid(props) {
	const { rows, columns, apiRef } = props;
	return (
		<CoAppDataGrid
			apiRef={apiRef}
			rows={rows}
			columns={columns}
			columnHeaderHeight={56}
			disableColumnMenu
			disableRowSelectionOnClick
			initialState={{
				pinnedColumns: { left: ["actions"] }
			}}
			hideFooter
			getRowHeight={() => "auto"}
			getEstimatedRowHeight={() => 100}
			disableVirtualization
			isscim="true"
			sx={{ maxHeight: { xxl: "590px", xl: "550", lg: "450px" } }}
			{...props}
		/>
	);
}