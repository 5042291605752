import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import { getDataGridFakeData } from "../../../../helpers/getDataGridFakeData";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useDatagridSorting from "../../../../hooks/useDatagridSorting";
import useToastAlert from "../../../../hooks/useToastAlert";
import { selectOrganizationId } from "../../../../redux/userSlice";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppDataGridSkeletonCell from "../../../global/components/datagrid/coapp-datagrid-skeleton-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/coapp-add-items-to-list-modal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import ThreeDotMenu from "../../../global/components/three-dot-menu";

const pluginGroupColumns = ["actions", "name", "users"];

export default function PluginGroups() {
	const dummyData = getDataGridFakeData(pluginGroupColumns);
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("pluginGroups");
	const { pluginId } = useParams();
	const { state } = useLocation();
	const organizationId = useSelector(selectOrganizationId);
	const currentPlugin = state.plugin ? state.plugin : state;

	const [pluginGroups, setPluginGroups] = useState(dummyData);
	const [pluginActive, setPluginActive] = useState(state.plugin ? state.plugin.active : state.active);
	const [removeGroupDialogOpen, setRemoveGroupDialogOpen] = useState(false);
	const [bulkRemoveGroupsDialogOpen, setBulkRemoveGroupsDialogOpen] = useState(false);
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [groupBeingRemoved, setGroupBeingRemoved] = useState({});
	const [addGroupsOpen, setAddGroupsOpen] = useState(false);
	const [groupsToAdd, setGroupsToAdd] = useState([]);
	const [availableGroups, setAvailableGroups] = useState([]);
	const [loading, setLoading] = useState(true);
	const [optionsLoading, setOptionsLoading] = useState(true);

	const columns = [
		{
			field: "name",
			headerName: "Name",
			sortable: true,
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={params.row} />}
				/>
			)
		},
		{
			field: "users",
			headerName: "Users",
			editable: false,
			sortable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridListCell items={params.value} resource="Users" isUser={true} />}
				/>
			)
		},
		{
			field: "actions",
			type: "actions",
			flex: .1,
			resizable: false,
			sortable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<div>
							<ThreeDotMenu
								options={[
									{
										name: "Remove",
										optionClickHandler: () => {
											handleOpenRemoveGroupConfirmDialog(params.row);
										}
									},
								]}
							/>
						</div>
					}
				/>
			),
		},
	];

	const initPluginGroups = () => {
		axios.get(apiRoutes.getGroupsForPlugin(pluginId), { headers: authHeader })
			.then((response) => {
				setPluginGroups(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching plugin groups: ", error);
			});
	};

	const initPluginStatus = () => {
		setPluginActive(state.isActive);
	};

	const handlePluginStatusToggleChange = () => {
		axios.put(apiRoutes.togglePluginStatusForOrganization(organizationId, pluginId), {}, { headers: authHeader })
			.then(() => {
				setPluginActive(!pluginActive);
			})
			.catch((error) => {
				console.error(error);
				handleToastAlert(messageLevels.ERROR, state.isActive ? messages.PLUGIN_DISABLED_ERROR_MSG : messages.PLUGIN_ENABLED_ERROR_MSG);
			});
	};

	const handleRemoveGroupFromPlugin = () => {
		axios.delete(apiRoutes.removeGroupFromPlugin(pluginId, groupBeingRemoved.id), { headers: authHeader })
			.then(() => {
				setRemoveGroupDialogOpen(false);
				initPluginGroups();
				handleToastAlert(messageLevels.INFO, messages.GROUP_REMOVAL_SUCCESS_MSG(false));
			})
			.catch((err) => {
				console.error(err);
				setRemoveGroupDialogOpen(false);
				handleToastAlert(messageLevels.ERROR, messages.GROUP_REMOVAL_ERROR_MSG);
			});
	};

	const handleBulkRemoveGroupsFromPlugin = () => {
		const deleteData = {
			groupIds: selectedGroups
		};

		axios.delete(apiRoutes.bulkRemoveGroupsFromPlugin(pluginId), { headers: authHeader, data: deleteData })
			.then(() => {
				setBulkRemoveGroupsDialogOpen(false);
				initPluginGroups();
				handleToastAlert(messageLevels.INFO, messages.GROUP_REMOVAL_SUCCESS_MSG(selectedGroups.length > 1));
			})
			.catch((err) => {
				console.error(err);
				setBulkRemoveGroupsDialogOpen(false);
				handleToastAlert(messageLevels.ERROR, messages.GROUP_REMOVAL_ERROR_MSG);
			});
	};

	const handleOpenRemoveGroupConfirmDialog = (group) => {
		setRemoveGroupDialogOpen(true);
		setGroupBeingRemoved(group);
	};

	const handleCloseRemoveGroupConfirmDialog = () => {
		setRemoveGroupDialogOpen(false);
		setGroupBeingRemoved({});
	};

	const handleAddGroupsToPlugin = () => {
		let postBody = {
			groups: groupsToAdd.map(group => group.id),
			pluginActiveStatus: pluginActive
		};
		axios.post(apiRoutes.addGroupsToPlugin(pluginId), postBody, { headers: authHeader })
			.then(() => {
				handleAddGroupsDialogToggle();
				initPluginGroups();
				handleToastAlert(messageLevels.SUCCESS, messages.GROUP_ADD_TO_PLUGIN_SUCCESS_MSG(groupsToAdd.length, currentPlugin.name));
			})
			.catch((err) => {
				console.error(err);
				handleToastAlert(messageLevels.ERROR, messages.GROUP_ADD_TO_PLUGIN_ERROR_MSG(groupsToAdd.length, currentPlugin.name));
			});
	};

	const handleAddGroupsToGroupsToAdd = (value) => {
		//value is an array of group names, we need to convert them to group objects to display group name properly
		value = value.map((group) => {
			let tempArr = [...pluginGroups, ...availableGroups];
			let foundGroup = tempArr.find((row) => row.name === group);
			return foundGroup;
		});
		setGroupsToAdd(value);
	};

	const handleOpenAddGroupsDialog = () => {
		axios.get(apiRoutes.getGroups, { headers: authHeader })
			.then((response) => {
				let currentGroups = pluginGroups.map(group => group.id);
				let availableGroups = response.data.filter(group => !currentGroups.includes(group.id));
				setAvailableGroups(availableGroups);
				setOptionsLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching groups: ", error);
			});
	};

	const handleAddGroupsDialogToggle = () => {
		if (!addGroupsOpen) {
			setAddGroupsOpen(true);
			handleOpenAddGroupsDialog();
		} else {
			setAddGroupsOpen(false);
			setAvailableGroups([]);
			setGroupsToAdd([]);
			setOptionsLoading(true);
		}
	};

	useEffect(() => {
		initPluginStatus();
		initPluginGroups();
		handleInitializeSortModel();
	}, []);

	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} Plugin Management`}
				resourceType="Plugin"
				subResourceType="Group"
				addIsPresent={true}
				addOnClickHandler={handleAddGroupsDialogToggle}
				editIsPresent={false}
				deleteIsPresent={false}
				toggleIsPresent={true}
				toggleHandler={handlePluginStatusToggleChange}
				toggleStatus={pluginActive}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={pluginGroups}
				pinnedColumns={["actions"]}
				allowSelection={true}
				targetResource="groups"
				parentResource="plugin"
				parentResourceName={currentPlugin.name}
				bulkActionText="Remove"
				selectionModel={selectedGroups}
				setSelectionModel={setSelectedGroups}
				handleModalToggle={() => setBulkRemoveGroupsDialogOpen(true)}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
				loading={loading}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={removeGroupDialogOpen}
				dialogTitle={`Remove ${groupBeingRemoved.name} from ${currentPlugin.name}?`}
				dialogMessage={messages.REMOVAL_CONFIRMATION_MSG(currentPlugin.name, groupBeingRemoved.name, "plugin", "group")}
				cancelClickHandler={handleCloseRemoveGroupConfirmDialog}
				confirmClickHandler={handleRemoveGroupFromPlugin}
				actionText="Remove"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={bulkRemoveGroupsDialogOpen}
				dialogTitle={`Remove selected groups (${selectedGroups.length}) from ${currentPlugin.name}?`}
				dialogMessage={`Are you sure that you want to remove the selected ${selectedGroups.length > 1 ? "groups" : "group"} from the ${currentPlugin.name} plugin?`}
				cancelClickHandler={() => setBulkRemoveGroupsDialogOpen(false)}
				confirmClickHandler={handleBulkRemoveGroupsFromPlugin}
				actionText="Remove"
			/>
			<CoAppAddItemsToListModal
				addButtonTitle="Save"
				inputLabel="Group(s)"
				addItemsIsToggled={addGroupsOpen}
				addItemsToCollection={handleAddGroupsToPlugin}
				addItemsToNewArray={handleAddGroupsToGroupsToAdd}
				dialogTitle={`Add Group(s) to ${currentPlugin.name} plugin`}
				items={availableGroups}
				itemsToAdd={groupsToAdd}
				toggleDialog={handleAddGroupsDialogToggle}
				loading={optionsLoading}
			/>
		</>
	);
}